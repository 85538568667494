// Basics
import MediaQuery from 'react-responsive';
import { Box } from '@mui/material';

// Theming
import { themeBreakpoints } from '../../../../shared/ui/themeVars';

// Featurees
import { CodeLineRange } from '../../../../features/moving-code/ui/CodeLineRange';

// Shared
import range from '../../../../shared/utils/range';

// Styles
import { s } from './sx';

export const CodeLineProvider = () => {

    let opacity = 6;

    // Static params to CodeLineRange component
    const codeLineParams = {
        isRandom: true,
        isAbsolute: false,
        language: 'typescript'
    };

    const createCodelines = (n: number) => {
        return range(n).map((i, key, arr) => {

            // Decrease opacity if it's order higher than half of the array and opposite
            if (i >= Math.ceil(arr.length / 2) || opacity >= 60) opacity -= 12;
            else opacity += 12;

            return (<CodeLineRange lines={3} {...codeLineParams} key={key} opacity={opacity} />);
        });
    };

    return (
        <Box sx={s.codelineWrapper}>
            {/* Creates highlighted moving lines of code */}

            {/* Small Phones */}
            <MediaQuery minWidth={themeBreakpoints.xs} maxWidth={themeBreakpoints.md - 1}>
                {createCodelines(12)}
            </MediaQuery>

            {/* Average Phones, Tablets, Desktops */}
            <MediaQuery minWidth={themeBreakpoints.md}>
                {createCodelines(16)}
            </MediaQuery>
        </Box>
    );
};
