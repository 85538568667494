// Libs
import axios from 'axios';

// Function gets random code from API on backend
export async function getRandomCode(amount: string | number) {
    try {
        const raw = await axios.get(`${process.env.REACT_APP_API_IP}:${process.env.REACT_APP_API_PORT}/api/codelines?amount=${amount}`);
        const response = raw.data;

        if(response.success) {
            return response.data;
        } else {
            console.log('Error getting codelines: ', response.error);
        }

    } catch (error) {
        console.error('Unexpected Error getting codelines:', error);
        throw error;
    }
}
