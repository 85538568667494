// Basics
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material';

// Styles
import './app/index.scss';

// Theming
import theme from './shared/ui/theme';

// Application
import App from './app';

// Translation
import { I18nextProvider } from 'react-i18next';
import i18n from './shared/i18n';
import { Provider } from 'react-redux';
import store from './redux/store';
import { SnackbarProvider } from 'notistack';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <ThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={4} autoHideDuration={6000}>
                    <App />
                </SnackbarProvider>
            </ThemeProvider>
        </I18nextProvider>
    </Provider>
  // </React.StrictMode>
);
