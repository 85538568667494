// Basics
import { Box } from '@mui/material';

// Widgets
import { ContentBox } from '../../../../widgets/content-box';

// Insides
import { BackgroundProvider } from './BackgroundProvider';
import { ContentProvider } from './ContentProvider';
import { LightBall } from '../../../../entities/light-ball';

// Styles
import { s } from './sx';

export const Benefits = () => {

    return (
        <Box component='section'>
            <ContentBox sx={s.wrapper}>

                <LightBall {...s.light.orange} />
                <LightBall {...s.light.blue} />

                <BackgroundProvider />
                <ContentProvider />

            </ContentBox>
        </Box>
    );
};
