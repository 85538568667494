// Basics
import { Box } from '@mui/material';
import MediaQuery from 'react-responsive';

// Theming
import { themeBreakpoints as breakpoints } from '../../../../shared/ui/themeVars';

// Features
import { VideoPlayer } from '../../../../features/video-player';

// Widgets
import { Bubble } from '../../../../widgets/floating-bubble';

// Styles
import { s } from './sx';
import { AppearanceProvider, Animations } from '../../../../features/animations/block-appearance';

// Assets
import frameXS from '../../../../shared/assets/images/frame_xs.svg';
import frameMD from '../../../../shared/assets/images/frame_md.svg';
import frameLG from '../../../../shared/assets/images/frame_lg.svg';
import frameXL from '../../../../shared/assets/images/frame_xl.svg';

import schemeMD from '../../../../shared/assets/images/scheme_md.svg';
import schemeLG from '../../../../shared/assets/images/scheme_lg.svg';
import schemeXL from '../../../../shared/assets/images/scheme_xl.svg';

export const BackgroundProvider = () => {

    return (
        <Box sx={s.background.wrapper}>

            {/* Content for Small Phones */}
            <MediaQuery minWidth={breakpoints.xs} maxWidth={breakpoints.md - 1}>

                {/* Frame of the Benefit Block */}
                <img src={frameXS} alt='Frame' id='Benefit-Block-Frame' style={{
                    ...s.background.static.img,
                    top: '-10px',
                    left: '-10px',
                }} />
                {/* Waves */}
                <VideoPlayer styles={s.background.dynamic.xs.waveUp} {...s.background.static.videoPlayer} />
                <VideoPlayer styles={s.background.dynamic.xs.waveDown} {...s.background.static.videoPlayer} />

                <Bubble trajectoryHeight={15} dur={5} sx={{ top: '190px', left: '60px', opacity: 0.85 }} sizes={[35, 35]} />
                <Bubble trajectoryHeight={20} dur={9} sx={{ top: '280px', left: '100px', opacity: 0.7 }} sizes={[42, 42] }/>
                <Bubble trajectoryHeight={30} dur={7} sx={{ top: '200px', left: '130px', opacity: 0.5 }} sizes={[25, 25]} />

            </MediaQuery>

            {/* Content for Average Phones */}
            <MediaQuery minWidth={breakpoints.md} maxWidth={breakpoints.lg - 1}>

                {/* Frame & Scheme of the Benefit Block */}
                <img src={frameMD} alt='Frame' id='Benefit-Block-Frame' style={{
                    ...s.background.static.img,
                    top: '-10px',
                    left: '-10px'
                }} />
                <AppearanceProvider dur={1} type={Animations.opacityUp}>
                    <img src={schemeMD} alt='Scheme' style={{ ...s.background.static.img, top: '300px', right: '20px' }} />
                </AppearanceProvider>

                {/* Waves */}
                <VideoPlayer styles={s.background.dynamic.md.waveUp} {...s.background.static.videoPlayer} />
                <VideoPlayer styles={s.background.dynamic.md.waveDown} {...s.background.static.videoPlayer} />

                <Bubble trajectoryHeight={20} dur={6} sx={{ top: '350px', left: '260px', opacity: 0.85 }} sizes={[35, 35]} />
                <Bubble trajectoryHeight={30} dur={10} sx={{ top: '380px', left: '100px', opacity: 0.7 }} sizes={[42, 42] }/>
                <Bubble trajectoryHeight={35} dur={8.5} sx={{ top: '420px', left: '200px', opacity: 0.5 }} sizes={[25, 25]} />

                <Bubble trajectoryHeight={15} dur={5} sx={{ top: '60px', left: '440px', opacity: 0.5 }} sizes={[35, 35]} />
                <Bubble trajectoryHeight={20} dur={5.5} sx={{ top: '120px', left: '480px', opacity: 0.4 }} sizes={[25, 25]} />

            </MediaQuery>

            {/* Content for Tablets */}
            <MediaQuery minWidth={breakpoints.lg} maxWidth={breakpoints.xl - 1}>

                {/* Frame & Scheme of the Benefit Block */}
                <img src={frameLG} alt='Frame' id='Benefit-Block-Frame' style={{
                    ...s.background.static.img,
                    top: '-7px',
                    left: '-12px'
                }} />
                <AppearanceProvider dur={1} type={Animations.opacityUp}>
                    <img src={schemeLG} alt='Scheme' style={{ ...s.background.static.img, top: '10px', right: '40px' }} />
                </AppearanceProvider>

                {/* Waves */}
                <VideoPlayer styles={s.background.dynamic.lg.waveUp} {...s.background.static.videoPlayer} />
                <VideoPlayer styles={s.background.dynamic.lg.waveDown} {...s.background.static.videoPlayer} />

                <Bubble trajectoryHeight={30} dur={6} sx={{ top: '350px', left: '200px', opacity: 0.85 }} sizes={[40, 40]} />
                <Bubble trajectoryHeight={40} dur={10} sx={{ top: '400px', left: '100px', opacity: 0.7 }} sizes={[50, 50] }/>
                <Bubble trajectoryHeight={40} dur={8.5} sx={{ top: '480px', left: '180px', opacity: 0.5 }} sizes={[30, 30]} />

                <Bubble trajectoryHeight={15} dur={5} sx={{ top: '50px', left: '520px', opacity: 0.5 }} sizes={[35, 35]} />
                <Bubble trajectoryHeight={20} dur={5.5} sx={{ top: '110px', left: '550px', opacity: 0.4 }} sizes={[25, 25]} />

            </MediaQuery>

            {/* Content for Desktops */}
            <MediaQuery minWidth={breakpoints.xl}>

                {/* Frame & Scheme of the Benefit Block */}
                <img src={frameXL} alt='Frame' id='Benefit-Block-Frame' style={{
                    ...s.background.static.img,
                    top: '-7px',
                    left: '-12px'
                }} />
                <AppearanceProvider dur={1} type={Animations.opacityUp}>
                    <img src={schemeXL} alt='Scheme' style={{ ...s.background.static.img, top: '15px', right: '93px' }} />
                </AppearanceProvider>

                {/* Waves */}
                <VideoPlayer styles={s.background.dynamic.xl.waveUp} {...s.background.static.videoPlayer} />
                <VideoPlayer styles={s.background.dynamic.xl.waveDown} {...s.background.static.videoPlayer} />

                <Bubble trajectoryHeight={40} dur={6} sx={{ top: '350px', left: '300px', opacity: 0.85 }} sizes={[50, 50]} />
                <Bubble trajectoryHeight={60} dur={10} sx={{ top: '450px', left: '160px', opacity: 0.7 }} />
                <Bubble trajectoryHeight={50} dur={8.5} sx={{ top: '550px', left: '280px', opacity: 0.5 }} sizes={[65, 65]} />

                <Bubble trajectoryHeight={20} dur={5} sx={{ top: '80px', left: '670px', opacity: 0.5 }} sizes={[35, 35]} />
                <Bubble trajectoryHeight={25} dur={5.5} sx={{ top: '120px', left: '730px', opacity: 0.4 }} sizes={[25, 25]} />

            </MediaQuery>
        </Box>
    );
};