// Basics
import { SxProps } from '@mui/material';

// Styles
export const s: Record<string, SxProps> = {
    title: {
        backgroundClip: 'text',
        textFillColor: 'transparent',
    },

    warmTitle: {
        background: 'linear-gradient(90deg, rgba(255, 169, 64, 1) 0%, rgba(255,255,255,1) 100%)',
    },

    coldTitle: {
        background: 'linear-gradient(90deg, rgba(100,168,209,1) 0%, rgba(255,255,255,1) 100%)',
    },

    contrastTitle: {
        background: 'linear-gradient(90deg, rgba(100,168,209,1) 0%, rgb(255, 140, 0) 50%)',
    }
};