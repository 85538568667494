// Basics
import MediaQuery from 'react-responsive';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

// Theming
import { themeBreakpoints as breakpoints } from '../../../../../../shared/ui/themeVars';

// Assets
import happyEmoji from '../../../../../../shared/assets/images/happy_emoji.svg';
import satisfiedEmoji from '../../../../../../shared/assets/images/satisfied_emoji.svg';
import neutralEmoji from '../../../../../../shared/assets/images/neutral_emoji.svg';
import sadEmoji from '../../../../../../shared/assets/images/sad_emoji.svg';

// Styles
import { s } from '../../sx';
import { AppearanceProvider, Animations } from '../../../../../../features/animations/block-appearance';

export const Display = () => {

    const { t } = useTranslation();

    return (
        <AppearanceProvider delay={1} dur={0.4} type={Animations.opacityUp}>
            {/* This Block is not displayed for phones at all */}
            <MediaQuery minWidth={breakpoints.xs} maxWidth={breakpoints.md - 1}><></></MediaQuery>
            <MediaQuery minWidth={breakpoints.md} maxWidth={breakpoints.lg - 1}><></></MediaQuery>

            {/* It does display to tablets and desktops though! */}
            <MediaQuery minWidth={breakpoints.lg}>
                <Stack direction='column-reverse' sx={s.content.sections.benefits.cost.display.wrapper}>
                    <Stack direction='row' alignItems='center'>
                        <Box component='div' sx={{ mr: { lg: '10px', xl: '16px' } }}><img src={happyEmoji} alt='Happy Emoji' height='42px' width='42px' /></Box>
                        <Typography sx={{ width: { lg: '200px', xl: '320px' } }} variant='h6' component='section' color='success.main'>{t('cost_happy_title')}</Typography>
                    </Stack>
                    <Stack direction='row' alignItems='center'>
                        <Box component='div' sx={{ mr: { lg: '10px', xl: '16px' } }}><img src={satisfiedEmoji} alt='Satisfied Emoji' height='42px' width='42px' /></Box>
                        <Typography sx={{ width: { lg: '200px', xl: '320px' } }} variant='h6' component='section' color='contrast.main'>{t('cost_satisfied_title')}</Typography>
                    </Stack>
                    <Stack direction='row' alignItems='center'>
                        <Box component='div' sx={{ mr: { lg: '10px', xl: '16px' } }}><img src={neutralEmoji} alt='Neutral Emoji' height='42px' width='42px' /></Box>
                        <Typography sx={{ width: { lg: '200px', xl: '320px' } }} variant='h6' component='section' color='contrast.light'>{t('cost_neutral_title')}</Typography>
                    </Stack>
                    <Stack direction='row' alignItems='center'>
                        <Box component='div' sx={{ mr: { lg: '10px', xl: '16px' } }}><img src={sadEmoji} alt='Sad Emoji' height='42px' width='42px' /></Box>
                        <Typography sx={{ width: { lg: '200px', xl: '320px' } }} variant='h6' component='section' color='error.main'>{t('cost_sad_title')}</Typography>
                    </Stack>
                </Stack>
            </MediaQuery>

        </AppearanceProvider>
    );
};