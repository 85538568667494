import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import { modalTranslateEN, modalTranslateRU } from './modalTranslate';

const resources = {
    en: {
        translation: {
            'not_found_title': 'Not Found! Error 404!',
            'not_found_subtitle': 'Unfortunetely we couldn‘t find the page you were looking for!',

            'idea': 'Idea',
            'project': 'Project',
            'order': 'Order',
            'job': 'Job',
            'contact': 'Contact',
            'model': 'Our model',
            'read_a_bit': 'Read A Bit!',
            'main_subtitle': 'Revolutionize Your Projects with Our Hybrid Model. Discover a dynamic blend of freelance flexibility and studio reliability. Access top-tier talent from our database, and let our expert managers orchestrate your project\'s success. Join us in redefining excellence!',

            'about_title_our_1': 'Our ',
            'about_title_idea_2': 'Idea',
            'about_title_goal_3': 'Goal',
            'about_subtitle': 'We recognize that sometimes, it\'s not about us – it\'s about your achievements. That\'s why we\'ve engineered our model to shine the spotlight on your projects, not ours. While we facilitate and ensure quality, your projects are rightfully credited to you, amplifying your brand\'s recognition and prominence. We operate silently in the background, orchestrating your success.',

            'model_title': 'The Unique Model',
            'model_subtitle': 'Our unique model is a combination of the best practices from both freelance and studios. The quality and reliability of studio and freedom and prices of freelance!',

            'benefits_title': 'Benefits of Our Model',

            'team_title': 'Make a team of your dream',
            'team_subtitle': 'Tell us your story and we’ll make a team of your dream! Don’t like it? Choose your own, we’ll give you people from our database that fit your personal requirements',

            'scale_title': 'Scalability',
            'scale_subtitle': 'As your business grows, our model evolves with you. Whether you\'re a startup looking for flexibility or a mature business seeking a more traditional studio experience, our model adapts to your changing needs.',

            'cost_title': 'Cost Efficiency',
            'cost_subtitle': 'By not maintaining a fixed team of in-house developers, you avoid the overhead costs associated with t raditional studios. Our commission-based manager system ensures that you pay for results, making your budget work harder for you.',

            'cost_sad_title': 'Need a Project. Studios’re so expensive these days!',
            'cost_neutral_title': 'Found CodeWave. Idea smells like a scam idea. Let’s see how it goes.',
            'cost_satisfied_title': 'Woah, just $5 and piece bun and they’re already making a second facebook?',
            'cost_happy_title': 'Holy! It’s amazing! They’ve made it in just a month?',

            'reviews_title': 'Some Fans Right Here',

            'hire_title': 'Understand this? Hired!',
            'hire_subtitle_p1': 'Your skills are our future! CodeWave invites talented developers who are ready for professional growth and challenges. If you\'re looking for an opportunity to develop, gain experience, and participate in big projects, then you\'re on the right path.',
            'hire_subtitle_p2': 'Join us and become a part of a team where your contribution is valued, and there\'s always room for your professional development. Click \'Join\' and let\'s create the future together!',
            'join_us': 'Apply',

            'time_to_order_title': 'Time to Order After All',
            'time_to_order_subtitle': 'Now that you are familiar with our capabilities and the professionalism of our developers. It\'s time to bring your ideas to life. Just click the \'Order\' button, and we will contact you shortly to discuss the details and start working on your project.',

            modal: modalTranslateEN
        }
    },
    ru: {
        translation: {
            'not_found_title': 'Страница не найдена! Ошибка 404!',
            'not_found_subtitle': 'К сожалению, нам не удалось найти страницу, которую вы искали!',

            'idea': 'Идея',
            'project': 'Проект',
            'order': 'Заказать',
            'job': 'Работа',
            'contact': 'Контакты',
            'model': 'Наша модель',
            'read_a_bit': 'Время Почитать!',
            'main_subtitle': 'Произведите революцию в Ваших проектах с помощью нашей гибридной модели. Откройте для себя сочетание гибкости фриланс-биржи и надежности студии. Получите доступ к талантам высшего уровня из нашей базы данных и позвольте нашим опытным менеджерам организовать успех вашего проекта. Присоединяйтесь к нам в переосмыслении совершенства!',

            'about_title_our_1': 'Наша ',
            'about_title_idea_2': 'Идея',
            'about_title_goal_3': 'Цель',
            'about_subtitle': 'Мы признаем, что иногда дело не в нас – дело в ваших достижениях. Вот почему мы разработали нашу модель, чтобы привлечь внимание к вашим проектам, а не к нашим. В то время как мы содействуем и гарантируем качество, ваши проекты по праву принадлежат вам, повышая узнаваемость и известность вашего бренда. Мы работаем бесшумно в фоновом режиме, организуя ваш успех.',

            'model_title': 'Уникальная Модель',
            'model_subtitle': 'Наша уникальная модель представляет собой сочетание лучших практик как фрилансеров, так и студий. Качество и надежность студии, а также свобода и цены фриланса!',

            'benefits_title': 'Наши Преимущества',

            'team_title': 'Создай Команду Мечты',
            'team_subtitle': 'Расскажите свою историю, и мы создадим команду вашей мечты! Не нравиться команда? Тогда соберите команду самостоятельно, мы предоставим вам людей из нашей базы, которые подходят вашим личным требованиям!',

            'scale_title': 'Масштабируемость',
            'scale_subtitle': 'По мере роста вашего бизнеса наша модель развивается вместе с вами. Независимо от того, являетесь ли вы стартапом, ищущим гибкости, или зрелым бизнесом, ищущим более традиционный опыт, наша модель адаптируется к вашим меняющимся потребностям!',

            'cost_title': 'Экономическая эффективность',
            'cost_subtitle': 'Не поддерживая постоянную команду штатных разработчиков, вы избегаете накладных расходов, связанных с традиционными студиями. Наша система управления, основанная на комиссионных, гарантирует, что вы платите за результаты, что делает ваш бюджет более выгодным для вас.',

            'cost_sad_title': 'Мне нужен проект, а студии стоят как 2 кабылы!',
            'cost_neutral_title': 'Нашёл CodeWave. Пахнет как скам. Ну ладно, посмотрим что тут.',
            'cost_satisfied_title': 'Воу, 500 рубелй и кусок батона, а они уже пилят мне 2-ой facebook!',
            'cost_happy_title': 'Погоди, чего? Они сделали его за месяц! Невероятно!',

            'reviews_title': 'А это наши Фанаты!',

            'hire_title': 'Понял код? Нанят!',
            'hire_subtitle_p1': 'Ваши навыки - наше будущее! CodeWave приглашает талантов, готовых к профессиональному росту и вызовам. Если вы ищете возможность развиваться, набираться опыта и участвовать в крупных проектах, то вы на правильном пути.',
            'hire_subtitle_p2': 'Присоединяйтесь к нам и станьте частью команды, где ваш вклад оценят и где всегда есть место для вашего профессионального роста. Нажмите \'Присоединиться\', и давайте создадим будущее вместе!',
            'join_us': 'Подать заявку',

            'time_to_order_title': 'Ну что же, начнём!',
            'time_to_order_subtitle': 'Теперь, когда вы ознакомились с нашими возможностями и увидели профессионализмом наших разработчиков. Пришло время воплотить ваши идеи в жизнь. Просто нажмите кнопку "Заказать", и мы свяжемся с вами в ближайшее время, чтобы обсудить детали и начать работу над вашим проектом.',

            modal: modalTranslateRU
        },
    }
};

const lang = localStorage.getItem('lang');
i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: lang ? lang : 'en',

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
