// Basics
import { createTheme, Shadows } from '@mui/material';

// Theme Variables
import { themeColors, themeBreakpoints, themeFontSize } from './themeVars';

// Update default theme types of Material-UI to support new palette colors
declare module '@mui/material/styles' {
    interface Palette {
        contrast?: {
            dark?: string;
            main?: string;
            light?: string;
        };
    }
    interface PaletteOptions {
        contrast?: {
            dark?: string;
            main?: string;
            light?: string;
        };
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        contrast: true
        'contrast.dark': true
        'contrast.main': true
        'contrast.light': true
    }
}

// Theme itself
const theme = createTheme({

    // Defines color palette
    palette: {
        mode: 'dark',
        ...themeColors
    },

    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...((ownerState.variant === 'contained' &&
                        ownerState.color === 'primary' && {
                        borderRadius: 8
                    })
                        ||
                        (ownerState.variant === 'text' && {
                            color: '#fff'
                        })),
                })
            },
        },
    },

    // Defines all styles to fonts
    typography: {
        fontFamily: 'QuickSand, Inter, Helvetica, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue',
    },

    // Defines breakpoints to width of the page
    breakpoints: {
        values: themeBreakpoints
    },

    // Gets rid of all shadows on the page
    shadows: Array(25).fill('none') as Shadows
});


// Define basic font sizes to different screen sizes & font types
theme.typography.h6 = {
    fontWeight: '400',
    fontSize: themeFontSize.h6.xs,
    [theme.breakpoints.up('xs')]: { fontSize: themeFontSize.h6.xs },
    [theme.breakpoints.up('md')]: { fontSize: themeFontSize.h6.md },
    [theme.breakpoints.up('lg')]: { fontSize: themeFontSize.h6.lg },
    [theme.breakpoints.up('xl')]: { fontSize: themeFontSize.h6.xl },
};
theme.typography.h5 = {
    fontSize: '12px',
    fontWeight: '500',
    [theme.breakpoints.up('xs')]: { fontSize: themeFontSize.h5.xs },
    [theme.breakpoints.up('md')]: { fontSize: themeFontSize.h5.md },
    [theme.breakpoints.up('lg')]: { fontSize: themeFontSize.h5.lg },
    [theme.breakpoints.up('xl')]: { fontSize: themeFontSize.h5.xl },
};
theme.typography.h4 = {
    fontSize: '12px',
    fontWeight: '500',
    [theme.breakpoints.up('xs')]: { fontSize: themeFontSize.h4.xs },
    [theme.breakpoints.up('md')]: { fontSize: themeFontSize.h4.md },
    [theme.breakpoints.up('lg')]: { fontSize: themeFontSize.h4.lg },
    [theme.breakpoints.up('xl')]: { fontSize: themeFontSize.h4.xl },
};
theme.typography.h3 = {
    fontSize: '16px',
    fontWeight: '600',
    [theme.breakpoints.up('xs')]: { fontSize: themeFontSize.h3.xs },
    [theme.breakpoints.up('md')]: { fontSize: themeFontSize.h3.md },
    [theme.breakpoints.up('lg')]: { fontSize: themeFontSize.h3.lg },
    [theme.breakpoints.up('xl')]: { fontSize: themeFontSize.h3.xl },
};
theme.typography.h2 = {
    fontSize: '20px',
    fontWeight: '600',
    [theme.breakpoints.up('xs')]: { fontSize: themeFontSize.h2.xs },
    [theme.breakpoints.up('md')]: { fontSize: themeFontSize.h2.md },
    [theme.breakpoints.up('lg')]: { fontSize: themeFontSize.h2.lg },
    [theme.breakpoints.up('xl')]: { fontSize: themeFontSize.h2.xl },
};
theme.typography.h1 = {
    fontSize: '24px',
    fontWeight: '700',
    [theme.breakpoints.up('xs')]: { fontSize: themeFontSize.h1.xs },
    [theme.breakpoints.up('md')]: { fontSize: themeFontSize.h1.md },
    [theme.breakpoints.up('lg')]: { fontSize: themeFontSize.h1.lg },
    [theme.breakpoints.up('xl')]: { fontSize: themeFontSize.h1.xl },
};
theme.typography.button = {
    fontSize: '12',
    fontWeight: '600',
    [theme.breakpoints.up('xs')]: { fontSize: themeFontSize.button.xs },
    [theme.breakpoints.up('md')]: { fontSize: themeFontSize.button.md },
    [theme.breakpoints.up('lg')]: { fontSize: themeFontSize.button.lg },
    [theme.breakpoints.up('xl')]: { fontSize: themeFontSize.button.xl }
};

export default theme;
