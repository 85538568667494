// Basics
import { Box, keyframes } from '@mui/material';

// Types
import { FloatingProviderParams } from '../utils/types';

export const Provider = ({
    children,

    height = 50,
    dur = 10,
    delay = 0,

    component = 'div',
    sx = {},
}: FloatingProviderParams) => {

    const floatingAnimation = keyframes`
        0% {
            transform: translateY(0px);
        }
        50% {
            transform: translateY(${height}px);
        }
        100% {
            transform: translateY(0px);
        }
    `;

    

    return (
        <Box component={component ? component : 'div'} sx={{
            animationName: `${floatingAnimation}`,
            animationDelay: `${delay}s`,
            animationDuration: `${dur}s`,

            animationIterationCount: 'infinite',
            animationTimingFunction: 'ease-in-out',
            ...sx
        }}>
            {children}
        </Box>
    );
};