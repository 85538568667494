// Basics
import { SxProps } from '@mui/material';
import { themeContentWidth } from '../../../shared/ui/themeVars';

// Styles
export const s: Record<string, SxProps> = {

    // Logo Styles
    logoWrapper: {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        fontSize: '18px'
    },

    logoIcon: {
        mr: '12px',
        width: '40px',
        height: '40px',
        flexShrink: '0',
    },

    headerWrapper: {
        background: 'rgba(0, 0, 0, 0.5)', 
        height: 64, 
        width: '100%',
        backdropFilter: 'blur(5px)',
        boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.43)',
        padding: { xs: '0 8px', lg: '0' }
    },

    headerContentWrapper: {
        margin: 'auto',
        width: {
            xs: '100%',
            md: '100%',
            lg: themeContentWidth.lg,
            xl: themeContentWidth.xl
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexFlow: 'row nowrap'
    }

};