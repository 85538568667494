// Basics
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

// Widgets
import { OrderActionBar } from '../../../../../widgets/order-action-bar';
import { ColdTitle } from '../../../../../widgets/titles';
import { Bubble } from '../../../../../widgets/floating-bubble';

// Features
import { AppearanceProvider } from '../../../../../features/animations/block-appearance';

// Styles
import { v } from '../../../../../shared/ui/sxVars';

export default function OrderBlock() {

    // Get Translations
    const { t } = useTranslation();

    return (
        <Stack direction='column' sx={{ position: 'relative', m: v.$blockMargins }}>

            {/* Title of the block with text gradient */}
            <AppearanceProvider sx={{ m: v.$titleMargins }} dur={0.4}>
                <ColdTitle>{t('time_to_order_title')}</ColdTitle>
            </AppearanceProvider>

            {/* Text of the Block */}
            <AppearanceProvider sx={{ width: { xs: '100%', lg: '60%', xl: '70%' }, m: v.$subtitleMargins }} delay={0.4} dur={0.4}>
                <Typography variant='h5' color='common.white'>{t('time_to_order_subtitle')}</Typography>
            </AppearanceProvider>

            {/* Action Bar (Buttons to Order & Read) */}
            <AppearanceProvider delay={0.4} dur={0.4}>
                <OrderActionBar />
            </AppearanceProvider>

            {/* Decorative Bubbles in the right */}
            <>
                <Bubble dur={8} sx={{
                    display: { xs: 'none', lg: 'block' },
                    zIndex: '-1',
                    top: { lg: '150px', xl: '150px', },
                    right: { lg: '40px', xl: '70px' }
                }} sizes={[
                    { lg: 40, xl: 75 }, { lg: 40, xl: 75 }
                ]} />

                <Bubble dur={6} sx={{
                    display: { xs: 'none', lg: 'block' },
                    zIndex: '-1',
                    top: { lg: '-150px', xl: '-200px', },
                    right: { lg: '0px', xl: '0px' }
                }} sizes={[
                    { lg: 75, xl: 90 }, { lg: 75, xl: 90 }
                ]} />

                <Bubble dur={5} sx={{
                    display: { xs: 'none', lg: 'block' },
                    zIndex: '-1',
                    top: { lg: '20px', xl: '0px', },
                    right: { lg: '130px', xl: '0px' }
                }} sizes={[
                    { lg: 60, xl: 50 }, { lg: 60, xl: 50 }
                ]} />
            </>

        </Stack>
    );
}