// Basics
import MediaQuery from 'react-responsive';

// Libs
import adapter from 'webrtc-adapter';

// Theming
import { themeBreakpoints } from '../../../../shared/ui/themeVars';

// Featurees
import { CodeLineRange } from '../../../../features/moving-code/ui/CodeLineRange';

export const CodeLineProvider = () => { // todo: fix bug(just make window width smaller and u'll see it)

    {/* Static params to CodeLineRange component */ }
    const codeLineParams = {
        opacity: adapter.browserDetails.browser === 'firefox' ? 40 : 15,
        isRandom: true,
        isAbsolute: true,
        language: 'typescript'
    };

    return (
        <>
            {/* Creates highlighted moving lines of code */}

            {/* Small Phones */}
            <MediaQuery minWidth={themeBreakpoints.xs} maxWidth={themeBreakpoints.md - 1}>
                <CodeLineRange lines={26} wrapperStyle={{ zIndex: '-2' }} {...codeLineParams} />
            </MediaQuery>

            {/* Average Phones */}
            <MediaQuery minWidth={themeBreakpoints.md} maxWidth={themeBreakpoints.lg - 1}>
                <CodeLineRange lines={28} wrapperStyle={{ zIndex: '-2' }} {...codeLineParams} />
            </MediaQuery>

            {/* Tablets */}
            <MediaQuery minWidth={themeBreakpoints.lg} maxWidth={themeBreakpoints.xl - 1}>
                <CodeLineRange lines={31} wrapperStyle={{ zIndex: '-2' }} {...codeLineParams} />
            </MediaQuery>

            {/* Desktops */}
            <MediaQuery minWidth={themeBreakpoints.xl}>
                <CodeLineRange lines={36} wrapperStyle={{ zIndex: '-2' }} {...codeLineParams} />
            </MediaQuery>
        </>
    );
};
