// Basics
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

// Widgets
import { ContrastTitle } from '../../../../../../widgets/titles';

// Benefits' Frames
import { Frame } from './Frame';

// Benefits' Displays
import { Display } from './Display';

// Styles
import { s } from '../../sx';
import { v } from '../../../../../../shared/ui/sxVars';
import { AppearanceProvider } from '../../../../../../features/animations/block-appearance';

export const TeamBlock = () => {

    const { t } = useTranslation();

    return (
        <Box sx={s.content.sections.benefits.team.wrapper}>

            {/* This is a frame for the Benefit's block */}
            <Frame />

            {/* Stack Container, wraps presenting image and text for small screens */}
            <Stack {...s.content.sections.benefits.team.stack}>
                <Box sx={{

                    /* Defines width relatively (as "Stack" comp is parental) */
                    width: { xs: '100%', lg: '147%' }
                }}>

                    {/* Title of the benefit block */}
                    <AppearanceProvider sx={{ m: v.$titleMargins }} delay={1.4} dur={0.4}>
                        <ContrastTitle variant='h3'>{t('team_title').toUpperCase()}</ContrastTitle>
                    </AppearanceProvider>

                    {/* Subtitle of the benefit block */}
                    <AppearanceProvider sx={{ m: v.$subtitleMargins, width: { xs: '80%', lg: '90%' } }} delay={1.8} dur={0.4}>
                        <Typography color="common.white" variant='h5'>{t('team_subtitle')}</Typography>
                    </AppearanceProvider>
                </Box>
                <Display />
            </Stack>
        </Box>
    );
};