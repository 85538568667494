// Basics
import { Box } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';

// Widgets
import { Header } from '../../../widgets/header';
import { Footer } from '../../../widgets/footer';
import HireOrderModal from '../../../widgets/hire-order-modal/HireOrderModal';

// Feature
import { AppearanceProvider } from '../../../features/animations/block-appearance';
import { Animations } from '../../../features/animations/block-appearance/utils/types';

// Layout is a wrapper for pages (mostly landing)
export const Layout = ({ children }: { children: ReactNode }) => {

    // Display css property of main
    const [ mainDisplay, setMainDisplay ] = useState('none');

    useEffect(() => {
        setTimeout(() => {setMainDisplay('block');}, 100);
    }, []);

    return (
        <main>
            {/* Animation for smooth website appearance from darkness */}
            <AppearanceProvider type={Animations.opacityDown} dur={1.5} sx={{
                zIndex: '10000',
                position: 'fixed',
                width: '100%',
                height: '100vh',
                background: '#000000'
            }}/>

            {/* Header of the page */}
            <Header />

            {/* Content of the page */}
            <Box sx={{ position: 'relative', display: mainDisplay }}>
                {children}
            </Box>

            {/* Footer of the page */}
            <Footer />

            <HireOrderModal/>
        </main>
    );
};
