// Basics
import MediaQuery from 'react-responsive';

// Theming
import { themeBreakpoints as breakpoints } from '../../../../../../shared/ui/themeVars';

// Assets
import displayBenefit2XS from '../../../../../../shared/assets/images/display_benefit_2_xs.svg';
import displayBenefit2MD from '../../../../../../shared/assets/images/display_benefit_2_md.svg';
import displayBenefit2LG from '../../../../../../shared/assets/images/display_benefit_2_lg.svg';
import displayBenefit2XL from '../../../../../../shared/assets/images/display_benefit_2_xl.svg';

// Styles
import { s } from '../../sx';
import { AppearanceProvider, Animations } from '../../../../../../features/animations/block-appearance';

export const Display = () => {

    return (
        <AppearanceProvider delay={1} dur={0.4} type={Animations.opacityUp} sx={s.content.sections.benefits.scale.display.wrapper}>

            {/* Define what presenting image to display relatively device's width */}
            <MediaQuery minWidth={breakpoints.xs} maxWidth={breakpoints.md - 1}>
                <img style={{ width: '100%' }} src={displayBenefit2XS} alt='Display Benefit' />
            </MediaQuery>
            <MediaQuery minWidth={breakpoints.md} maxWidth={breakpoints.lg - 1}>
                <img style={{ width: '100%' }} src={displayBenefit2MD} alt='Display Benefit' />
            </MediaQuery>
            <MediaQuery minWidth={breakpoints.lg} maxWidth={breakpoints.xl - 1}>
                <img style={{ width: '100%' }} src={displayBenefit2LG} alt='Display Benefit' />
            </MediaQuery>
            <MediaQuery minWidth={breakpoints.xl}>
                <img style={{ width: '100%' }} src={displayBenefit2XL} alt='Display Benefit' />
            </MediaQuery>
        </AppearanceProvider>
    );
};