// Basics
import React, { useRef, useEffect } from 'react';

// Types
import { VideoPlayerProps } from '../utils/types';

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
    src,
    styles,
    className,
    doControl = false,
    doLoop = false,
    doStart = false,
    volume = 50,
}) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        const videoElement = videoRef.current;
        if (!videoElement) return;

        videoElement.controls = doControl;
        videoElement.loop = doLoop;
        videoElement.volume = volume / 100;

        if (doStart) videoElement.play().catch((e) => {
            console.log(`Error happened when video played tried to play\nSource: ${src}\nError:${e}`);
        });

    }, [doControl, doLoop, doStart, volume]);

    return (
        <video ref={videoRef} src={src} style={styles} className={className} />
    );
};