// Basics
import { useLayoutEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

// Widgets
import { GlaringFrame } from '../../glaring-frame';

// API
import { getRandomReviews } from '../api/getRandomReviews';

// Types
import { ReviewContentType, ReviewType } from '../utils/types';

// Styles
import { s } from '../utils/sx';

export const Review = ({
    isRandom = true, // Defines wether to get random content or define it by yourself
    data, // The data of review if it's not random
    sxFrame, // SX styles for frame
    sxContainer // SX styles for inner container
}: ReviewType) => {

    const [review, setReview] = useState<ReviewContentType>({
        status: 'customer',
        username: 'Username',
        content: 'I like CodeWave.'
    });

    useLayoutEffect(() => {

        // Get random review if it's enabled
        if (isRandom) {
            (async () => {
                const response = await getRandomReviews(1);
                setReview(response[0]);
            })();
        }

        // Get content of review from data if random is disabled
        else if (data && data.username && data.content && data.status) {
            setReview({
                username: data.username,
                content: data.content,
                status: data.status
            });
        }

        // If none of above is true
        else console.log('The review content is undefined in params!');
    }, [data]);

    // Style Vars
    const reviewWrapper = { ...s.reviewWrapper, ...sxContainer };

    return (
        <GlaringFrame sx={sxFrame}>
            <Box component='div' sx={reviewWrapper}>

                {/* Username & Status */}
                <Typography color="common.white" variant='h6' sx={s.reviewTitle}>
                    {/* Username and colorful role in brackets */}
                    <span>{review.username}</span>
                    {' ('}
                    {
                        review.status === 'customer' ?
                            <span style={{ color: '#0288D1' }}>{review.status.toUpperCase()}</span> :
                            <span style={{ color: '#56B35C' }}>{review.status.toUpperCase()}</span>
                    }
                    {')'}
                </Typography>

                {/* Content of the review */}
                <Typography sx={s.reviewContent} variant='h6'>
                    {review.content}
                </Typography>
            </Box>
        </GlaringFrame>
    );
};
