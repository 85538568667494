// Types
import { BubbleParams } from '../utils/types';

// Features
import { FloatingProvider } from '../../../features/animations/floating';

// Assets
import bubble from '../../../shared/assets/images/bubble.svg';

export const Bubble = ({
    sizes = [80, 80],
    trajectoryHeight = 50,
    
    dur = 10,
    delay = 0,

    sx = {}
}: BubbleParams) => {

    return (
        <FloatingProvider height={trajectoryHeight} dur={dur} delay={delay} component='span' sx={{
            height: sizes[0],
            width: sizes[1],
            position: 'absolute',
            ...sx
        }}>
            <img src={bubble} alt='Floating Bubble' height={'100%'} width={'100%'}/>
        </FloatingProvider>
    );

};