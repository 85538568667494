// Basics
import MediaQuery from 'react-responsive';

// Theming
import { themeBreakpoints as breakpoints } from '../../../../../../shared/ui/themeVars';

// Assets
import displayBenefit1XS from '../../../../../../shared/assets/images/display_benefit_1_xs.svg';
import displayBenefit1XL from '../../../../../../shared/assets/images/display_benefit_1_xl.svg';

// Styles
import { s } from '../../sx';
import { AppearanceProvider, Animations } from '../../../../../../features/animations/block-appearance';

export const Display = () => {

    return (
        <AppearanceProvider delay={1} dur={0.4} type={Animations.opacityUp} sx={s.content.sections.benefits.team.display.wrapper}>

            {/* Define what presenting image to display relatively device's width */}
            <MediaQuery minWidth={breakpoints.xs} maxWidth={breakpoints.xl - 1}>
                <img style={{ width: '100%' }} src={displayBenefit1XS} alt='Display Benefit' />
            </MediaQuery>
            <MediaQuery minWidth={breakpoints.xl}>
                <img src={displayBenefit1XL} alt='Display Benefit' />
            </MediaQuery>
        </AppearanceProvider>
    );
};