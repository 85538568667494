// Basics
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

// Styles
import { s } from '../utils/sx';

// Libs
import { animateScroll as scroll } from 'react-scroll';

// Theming
import { themeBreakpoints as breakpoints } from '../../../shared/ui/themeVars';

// Assets
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { onOrderClick } from '../../../shared/utils/clickEvents';

// Widger Action Bar for places related to Ordering
export const ActionBar = () => {

    // Get translations
    const { t } = useTranslation();

    const onReadClick = () => {
        let to = 468;

        const w = window.innerWidth;

        if (w >= breakpoints.xl) to = 935;
        else if (w >= breakpoints.lg) to = 783;
        else if (w >= breakpoints.md) to = 583;

        scroll.scrollTo(to);
    };

    return (
        <Box sx={s.actionBar}>

            {/* Button to Order our Service */}
            <Button

                variant='contained' color='primary'
                sx={s.button} onClick={onOrderClick}

            >{t('order').toUpperCase()}</Button>

            {/* Button to read more about us */}
            <Button

                endIcon={<ArrowForwardIosRoundedIcon />}
                variant='outlined' color='contrast'
                sx={s.button} onClick={onReadClick}

            >{t('read_a_bit').toUpperCase()}</Button>

        </Box>
    );
};
