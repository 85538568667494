// Basics
import { SxProps } from '@mui/material';

export const s: Record<string, SxProps> = {

    reviewWrapper: {
        background: '#121212',
        borderRadius: '8px',
        p: {
            xs: '16px 9px',
            md: '28px 15px',
            lg: '32px 18px',
            xl: '34px 20px'
        }
    },

    reviewTitle: {
        mb: {
            xs: '8px',
            md: '16px',
            lg: '18px'
        }
    },

    reviewContent: {
        color: '#B1B1B1'
    }
};