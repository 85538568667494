// Basics
import { SxProps } from '@mui/material';

// Variables
import { v } from '../../../shared/ui/sxVars';

// Styles
export const s: Record<string, SxProps> = {

    button: {
        p: { xs: '6px 22px', md: '10px 28px', lg: '14px 36px' }
    },

    actionBar: {
        display: 'flex',
        m: v.$actionMargins,
        gap: { xs: '0 10px', xl: '0px 26px' }
    }

};