// Basics
import { Box } from '@mui/material';

// Libs
import 'highlight.js/styles/tomorrow-night-bright.css';

// Shared
import range from '../../../shared/utils/range';

// inner API
import { CodeLine } from './CodeLine';

// Types
import type { CodeRangeParams } from '../utils/types';

// Styles
import { s } from '../utils/sx';

export const CodeLineRange = ({
    isAbsolute = true,
    language = 'typescript',
    lines = 1,
    opacity = 100,
    wrapperStyle,
    lineStyle
}: CodeRangeParams) => {

    // Params for codeLine components
    const codeLineParams = {
        language,
        style: lineStyle
    };

    // Merge inner and param's styles
    const codeRangeStyles = {
        ...s.codelineWrapper,
        ...wrapperStyle
    };

    return (
        <Box component='span' sx={{
            opacity: `${opacity}%`,
            position: isAbsolute ? 'absolute' : 'static',
            ...codeRangeStyles
        }}>

            {/* Create amount of codelines defined in "lines" */}
            {range(lines).map((i, key) => <CodeLine key={key} {...codeLineParams} /> )}

        </Box>
    );
};
