// Basics
import { Box, SxProps } from '@mui/material';
import { useRef } from 'react';

// Types
import { Animations, AppearanceProviderParams } from '../utils/types';

// Styles
import { appearanceKeyframes } from '../utils/sx';
import useOnScreen from '../../../../shared/hooks/useOnScreen';

export const Provider = ({
    children,

    delay = 0,
    dur = 0.3,

    type = Animations.appearance,
    component = 'div',
    sx = {} as SxProps
}: AppearanceProviderParams) => {
    const providerRef = useRef<HTMLElement>(null);

    const onScreen = useOnScreen(providerRef);

    return (
        <Box ref={providerRef} component={component ? component : 'div'} sx={{
            animationDelay: `${delay}s`,
            animationDuration: `${dur}s`,
            opacity: 0,

            animationFillMode: 'forwards',
            animationTimingFunction: 'ease-in',
            ...(onScreen ? { animationName: appearanceKeyframes[type].toString() } : undefined),
            ...sx
        }}>
            {children}
        </Box>
    );
};
