// Basics
import { Typography } from '@mui/material';

// Libs
import { Marquee } from './Marquee';

// Types
import type { CodeLineParams } from '../utils/types';

// Styles
import { s } from '../utils/sx';

export const CodeLine = ({
    language = 'typescript',
    style = {}
}: CodeLineParams) => {

    // Merge inner and param's styles
    const codelineStyles = { ...style, ...s.codeline };

    return (
        <Marquee speed={Math.random() * 15 + 8}>
            <pre>
                <Typography
                    component='code' 
                    className={`language-${language}`} 
                    sx={codelineStyles} 

                >
                    {
                        'const success = (Idea, Team, Vision) => console.log(Idea); '
                        + 'const success = (Idea, Team, Vision) => console.log(Idea); const success = (Idea, Team, Vision) => console.log(Idea); '
                        + 'const success = (Idea, Team, Vision) => console.log(Idea); const success = (Idea, Team, Vision) => console.log(Idea); const success = (Idea, Team, Vision) => console.log(Idea); '
                    }
                </Typography>
            </pre>
        </Marquee>
    );
};