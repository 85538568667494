// Basics
import { Box } from '@mui/material';

// Widgets
import { ContentBox } from '../../../../widgets/content-box';

// Blocks
import ReviewBlock from './blocks/review';
import HireBlock from './blocks/hire';
import OrderBlock from './blocks/order';

// Providers
import { CodeLineProvider } from './CodeLineProvider';

// Styles
import { s } from './sx';
import { useEffect, useRef } from 'react';

export const Agitation = () => {
    const agitationRef = useRef<HTMLElement>(null);

    useEffect(() => {
        if (agitationRef.current){
            new IntersectionObserver(([entry]) => console.log(entry)).observe(agitationRef.current);
        }
    }, []);

    return (
        <Box component="section" sx={s.wrapper} ref={agitationRef}>
            <CodeLineProvider />

            {/* ContentBox is responsive container for all content so it never left the screen size */}
            <ContentBox>

                {/* Block for Reviews */}
                <ReviewBlock />

                {/* Block for Hiring Developers */}
                <HireBlock />

                {/* Block to Order our Service */}
                <OrderBlock />

            </ContentBox>
        </Box>
    );
};
