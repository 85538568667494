import { keyframes } from '@mui/material';
import { Keyframes } from '@emotion/react';

import { Animations } from './types';


// Animations
export const appearanceKeyframes: Record<Animations, Keyframes> = {
    appearance: keyframes`
        0%   { 
            transform: translate(0, -25%);
            opacity: 0;
        }
        100% { 
            transform: translate(0, 0);
            opacity: 1;
        }
    `,

    scaleUp: keyframes`
        0%   { 
            transform: scale(0);
            opacity: 0;
        }
        100% { 
            transform: scale(1);
            opacity: 1;
        }
    `,

    opacityUp: keyframes`
        0%   { 
            opacity: 0;
        }
        100% { 
            opacity: 1;
        }
    `,

    opacityDown: keyframes`
        0%   { 
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
        100% {
            z-index: -10000;
            opacity: 0;
        }
    `,

    leftToRight: keyframes`
        0% {
            opacity: 0;
            transform: translateX(-30%);
        } 
        100% {
            opacity: 1;
            transform: translateX(0%); 
        }
    `,

    rightToLeft: keyframes`
        0% {
            opacity: 0;
            transform: translateX(+30%);
        }
        100% {
            opacity: 1;
            transform: translateX(0%);
        }
    `
};