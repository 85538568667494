// Basics
import { Box, SxProps } from '@mui/material';
import React from 'react';

export const GlaringFrame = ( { children, sx }: { children: React.ReactNode, sx?: SxProps } ) => {
    return (
        <Box sx={{
            width: 'auto',
            heught: 'auto',
            padding: '1px',
            boxSizing: 'border-box',
            borderRadius: '8px',
            background: 'linear-gradient(145deg, #64A8D1 0%, #FFF 10%, #121212 30%, #121212 75%, #FFF 90%, #64A8D1 100%)',
            ...sx
        }}>
            {children}
        </Box>
    );
};