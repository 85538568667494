// Basics
import { Typography, Box } from '@mui/material';

// Theming
import theme from '../../../shared/ui/theme';

// Styles
import { s } from '../utils/sx';

// Assets
import logo from '../../../shared/assets/images/logo.svg';

export const Logo = () => {

    return (
        <Typography component='span' sx={s.logoWrapper}>

            {/* Logo Icon */}
            <Box sx={s.logoIcon}>
                <img src={logo} alt='Logo' style={{
                    color: '#ffffff',
                    width: '100%',
                    height: '100%',
                    userSelect: 'none'
                }} />
            </Box>

            {/* Logo Name */}
            <a href='/'>
                <Box component='span' sx={{
                    color: theme.palette.contrast?.dark
                }}>Code</Box>
                <Box component='span' sx={{
                    color: theme.palette.primary?.main
                }}>Wave</Box>
            </a>

        </Typography>
    );
};