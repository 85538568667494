// Basics
import { ReactNode, useEffect, useRef } from 'react';
import { Box } from '@mui/material';

// Libs
import hljs from 'highlight.js';

// Styles
import { s } from '../utils/sx';


export const Marquee = ({ children, speed }: { children: ReactNode, speed: number }) => {

    // HTML elements
    const firstLineRef = useRef<HTMLDivElement>(null);
    const secondLineRef = useRef<HTMLDivElement>(null);

    useEffect(() => {

        // In the case HTML elements aren't found
        if (!firstLineRef.current || !secondLineRef.current) return;

        // Highlight elements
        hljs.highlightElement(firstLineRef.current.firstElementChild!.firstElementChild as HTMLElement);
        hljs.highlightElement(secondLineRef.current.firstElementChild!.firstElementChild as HTMLElement);
    }, []);

    return (
        <Box sx={s.marqueeWrapper}>
            <Box sx={s.maqrueeLine} ref={firstLineRef} style={{ animationDuration: speed + 's' }}>{children}</Box>
            <Box sx={s.maqrueeLine} ref={secondLineRef} style={{ animationDuration: speed + 's' }}>{children}</Box>
        </Box>
    );
};