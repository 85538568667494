// Basics
import { Box, Button, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Libs
import { animateScroll as scroll } from 'react-scroll';

// Shared
import { onOrderClick } from '../../../shared/utils/clickEvents';

// Theming
import { themeBreakpoints as breakpoints } from '../../../shared/ui/themeVars';

// Assets
import ukFlag from '../../../shared/assets/images/gb.svg';
import ruFlag from '../../../shared/assets/images/ru.svg';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CelebrationRoundedIcon from '@mui/icons-material/CelebrationRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';
import LightbulbRoundedIcon from '@mui/icons-material/LightbulbRounded';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { actions } from '../../../redux/modalSlice';

const { setLanguage } = actions;

export const Navbar = () => {
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();

    const lang = useAppSelector(s=>s.modalSlice.lang);
    const [menuOpen, setMenuOpen] = useState(false);

    const menuBtnRef = useRef<HTMLButtonElement>(null);

    // Events
    const onLanguageSwitch = async () => {
        await i18n.changeLanguage(lang ? 'en' : 'ru');
        dispatch(setLanguage());
    };

    const handleBurgerClick = () => setMenuOpen(x => !x);
    const handleBurgerClose = () => setMenuOpen(false);

    const onContactClick = () => scroll.scrollToBottom();
    const onModelClick = () => {
        let to = 468;

        const w = window.innerWidth;

        if (w >= breakpoints.xl) to = 935;
        else if (w >= breakpoints.lg) to = 783;
        else if (w >= breakpoints.md) to = 583;

        scroll.scrollTo(to);
    };
    const onJobClick = () => {
        let to = 2800;

        const w = window.innerWidth;

        if (w >= breakpoints.xl) to = 5200;
        else if (w >= breakpoints.lg) to = 4000;
        else if (w >= breakpoints.md) to = 3650;

        scroll.scrollTo(to);
    };;

    return (
        <>
            {/* Navigation bar if TABLET / DESKTOP */}
            <Box sx={{
                display: {
                    xs: 'none',
                    lg: 'flex'
                },
                gap: '0 20px',

            }}>

                {/* List of Buttons in Menu */}
                <Button sx={{ p: '8px 16px' }} variant='contained' onClick={onOrderClick}>{t('order')}</Button>
                <Button sx={{ p: '8px 16px' }} onClick={onJobClick}>{t('job')}</Button>
                <Button sx={{ p: '8px 16px' }} onClick={onModelClick}>{t('model')}</Button>
                <Button sx={{ p: '8px 16px' }} onClick={onContactClick}>{t('contact')}</Button>
                <IconButton onClick={onLanguageSwitch} sx={{ color: '#fff' }}>
                    <img src={lang ? ruFlag : ukFlag} alt='Flag' width={30} height={30} style={{ borderRadius: '4px' }}/>
                </IconButton>
            </Box>

            {/* Burger menu if PHONE */}
            <Box component={'span'} sx={{
                display: {
                    xs: 'block',
                    lg: 'none'
                }
            }}>
                {/* Icon Burger Button to open Floating menu */}
                <IconButton sx={{ color: '#fff' }} onClick={handleBurgerClick} ref={menuBtnRef}>
                    <MenuRoundedIcon sx={{ color: '#fff', width: '30px', height: '30px' }}/>
                </IconButton>

                {/* Floating menu for small devices */}
                <Paper elevation={1}>
                    <Menu open={menuOpen} anchorEl={menuBtnRef.current}
                        onClose={handleBurgerClose} onClick={handleBurgerClose}>
                        <MenuItem sx={{ width: '240px' }} onClick={onOrderClick}>
                            <ListItemIcon><CelebrationRoundedIcon/></ListItemIcon>
                            <ListItemText>{t('order')}</ListItemText>
                        </MenuItem>
                        <Divider/>
                        <MenuItem onClick={onJobClick}>
                            <ListItemIcon><InfoRoundedIcon/></ListItemIcon>
                            <ListItemText>{t('job')}</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={onContactClick}>
                            <ListItemIcon><AlternateEmailRoundedIcon/></ListItemIcon>
                            <ListItemText>{t('contact')}</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={onModelClick}>
                            <ListItemIcon><LightbulbRoundedIcon/></ListItemIcon>
                            <ListItemText>{t('model')}</ListItemText>
                        </MenuItem>
                        <Divider/>
                        <MenuItem onClick={onLanguageSwitch}>
                            {/* Define icon and name to button relatively language */}
                            <img src={lang ? ruFlag : ukFlag} alt='Language' width={30} height={30}
                                style={{ borderRadius: '4px', marginRight: '10px' }}/>
                            <span>{lang ? 'Русский' : 'English'}</span>
                        </MenuItem>
                    </Menu>
                </Paper>
            </Box>
        </>
    );
};
