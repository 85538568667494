// Basics
import { Stack, Typography, Box } from '@mui/material';

// Styles
import { v } from '../../../shared/ui/sxVars';

export const Footer = () => {

    return (
        <Stack alignItems='center' component='footer' spacing={1} sx={{ mt: '12vh', mb: '20px' }}>
            <Box sx={{
                background: 'linear-gradient(270deg, rgba(61, 154, 209, 0.00) 0%, #3D9AD1 10%, #FFF 50%, #3D9AD1 90%, rgba(61, 154, 209, 0.00) 100%)',
                width: '90%',
                height: '1px'
            }} />
            <Typography variant='h6' color='common.white'>
                {`© CodeWave, ${new Date().getFullYear()}`}
            </Typography>
        </Stack>
    );
};