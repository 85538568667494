// Basics
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

// Widgets
import { ColdTitle } from '../../../../../../widgets/titles';

// Benefits' Frames
import { Frame } from './Frame';

// Benefits' Displays
import { Display } from './Display';

// Styles
import { s } from '../../sx';
import { v } from '../../../../../../shared/ui/sxVars';
import { AppearanceProvider } from '../../../../../../features/animations/block-appearance';

export const ScaleBlock = () => {

    const { t } = useTranslation();

    return (
        <Box sx={s.content.sections.benefits.scale.wrapper}>

            {/* This is a frame for the Benefit's block */}

            {/* Stack Container, wraps presenting image and text for small screens */}
            <Stack {...s.content.sections.benefits.scale.stack}>
                <Display />
                <Box sx={{
                    position: 'relative',

                    /* Defines width relatively (as "Stack" comp is parental) */
                    width: { xs: '100%' },

                    ml: { xs: '0', lg: '40px' }

                }}>
                    <Frame />

                    <Box sx={{
                        pt: { xs: '24px', md: '30px', xl: '32px' },
                        ml: { xs: '0', lg: '40px' }
                    }}>
                        {/* Title of the benefit block */}
                        <AppearanceProvider sx={{ m: v.$titleMargins }} delay={1.4} dur={0.4}>
                            <ColdTitle variant='h3'>{t('scale_title').toUpperCase()}</ColdTitle>
                        </AppearanceProvider>

                        {/* Subtitle of the benefit block */}
                        <AppearanceProvider sx={{ m: v.$subtitleMargins, width: { xs: '80%', lg: '90%' } }} delay={1.8} dur={0.4}>
                            <Typography color="common.white" variant='h5'>{t('scale_subtitle')}</Typography>
                        </AppearanceProvider>
                    </Box>
                </Box>
            </Stack>
        </Box>
    );
};
