type ModalTranslateFields = 'headerOrder' | 'headerHire' | 'email' | 'name'
    | 'informationOrder' | 'informationHire' | 'error' | 'success'
    | 'invalidEmail' | 'invalidName' | 'invalidContent';

type ModalTranslate = {
    [K in ModalTranslateFields]: string
}

export const modalTranslateEN: ModalTranslate = {
    headerOrder: 'Order time! Fill these fields and wait!',
    headerHire: 'We\'re hiring! First step to start the journey!',
    email: 'Your Email',
    name: 'Name & Surname',
    informationOrder: 'Your Wishes. Try to describe us what you need and who are you looking for',
    informationHire: 'Tell us about you. Send us your resume and contact information',
    error: 'An error has occurred. Try again later',
    success: 'Form submitted successfully. Thank you for your time',
    invalidEmail: 'Invalid email address. Please provide the valid one',
    invalidName: 'Invalid name & surname. It should be not empty, consist only of letters and contain at least 5 characters',
    invalidContent: 'Invalid information. It should be not empty and contain at least 20 characters'
};

export const modalTranslateRU: ModalTranslate = {
    headerOrder: 'Время заказывать! Заполните эти поля и ожидайте!',
    headerHire: 'Мы ищем разработчиков! Здесь начнется твоя карьера!',
    email: 'Ваша электронная почта',
    name: 'Имя & Фамилия',
    informationOrder: 'Ваши пожелания. Постарайтесь описать нам что вы хотите и кого вы ищите',
    informationHire: 'Расскажите нам о себе. Отправьте свое резюме и контактную информацию',
    error: 'Произошла ошибка. Попробуйте позже',
    success: 'Форма успешно отправлена. Спасибо вам за уделенное время',
    invalidEmail: 'Некорректный адрес электронной почты',
    invalidName: 'Некорректное имя & фамилия. Оно должно быть не пустым, состоять только из букв и содержать, как минимум, 5 символов',
    invalidContent: 'Некорректная информация. Оно должно быть не пустым и содержать, как минимум, 20 символов'
};
