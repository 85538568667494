// Basics
import { useEffect, useRef } from 'react';
import { Stack, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

// Libs
import 'highlight.js/styles/tomorrow-night-bright.css';
import hljs from 'highlight.js';

// Widgets
import { GlaringFrame } from '../../../../../widgets/glaring-frame';
import { ColdTitle } from '../../../../../widgets/titles';
import { actions, ModalType } from '../../../../../redux/modalSlice';
import { useAppDispatch } from '../../../../../redux/store';

// Features
import { Animations, AppearanceProvider } from '../../../../../features/animations/block-appearance';

// Styles
import { v } from '../../../../../shared/ui/sxVars';

const { openModal } = actions;

export default function HireBlock() {
    const dispatch = useAppDispatch();

    const { t } = useTranslation();

    const codeRef = useRef<HTMLElement>(null);

    // Text highlighting setup
    useEffect(() => {
        if (codeRef.current) hljs.highlightElement(codeRef.current);
    }, []);

    const onJoinClick = () => dispatch(openModal(ModalType.job));

    return (
        <Stack sx={{ m: v.$blockMargins }} direction='column'>

            {/* Title of the block with text gradient */}
            <AppearanceProvider sx={{ m: v.$titleMargins }} dur={0.4}>
                <ColdTitle>{t('hire_title')}</ColdTitle>
            </AppearanceProvider>

            {/* The Code */}
            <AppearanceProvider sx={{ m: v.$codeMargins }} delay={0.4} dur={0.4} type={Animations.opacityUp}>
                <pre>

                    {/* GlarinFrame component adds little glaring border to code element */}
                    <GlaringFrame sx={{ width: { xs: '100%', lg: '65%', xl: '70%' } }}>
                        <Typography sx={{ width: '100%', borderRadius: '8px' }} component='code' variant={'h6'} className='language-javascript' ref={codeRef}>
                            {`const success = (Idea, Team, Vision) => {
    const PROJECT = new codeWave(Idea, Team, Vision);
    return PROJECT;
}`}
                        </Typography>
                    </GlaringFrame>
                </pre>
            </AppearanceProvider>

            {/* Text of the Block */}
            <AppearanceProvider sx={{ m: v.$subtitleMargins }} delay={0.8} dur={0.4}>
                <Typography component='p' variant='h5' color='common.white' sx={{ width: { xs: '100%', lg: '60%', xl: '70%' }, mb: '0.8rem' }}>{t('hire_subtitle_p1')}</Typography>
                <Typography component='p' variant='h5' color='common.white' sx={{ width: { xs: '100%', lg: '60%', xl: '70%' } }}>{t('hire_subtitle_p2')}</Typography>
            </AppearanceProvider>

            {/* Button to Join Team */}
            <AppearanceProvider sx={{ m: v.$actionMargins }} delay={0.4} dur={0.4} component='section'>
                <Button onClick={onJoinClick} variant='contained' sx={{
                    p: { xs: '9px 28px', md: '11px 36px', lg: '12px 48px' }
                }}>{t('join_us').toUpperCase()}</Button>
            </AppearanceProvider>

        </Stack>
    );
}
