import { createAsyncThunk } from '@reduxjs/toolkit';
import { processRequest, ThunkApi } from '../shared/utils/processRequest';
import axios from 'axios';
import { RootState } from './store';
import { ModalData } from './modalSlice';

const API_URL = process.env.REACT_APP_API_IP + ':' + process.env.REACT_APP_API_PORT + '/api/';

export const submitModal = createAsyncThunk(
    'submitModal',
    async (_, thunkAPI: ThunkApi) => {
        const { type, email, name, content, reject } = (thunkAPI.getState() as RootState).modalSlice;

        if (reject) return thunkAPI.rejectWithValue();

        const data: Partial<ModalData> = {
            type,
            email,
            name,
            content
        };

        return await  processRequest(axios.post(API_URL + 'notify', data), thunkAPI);
    }
);
