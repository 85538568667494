// Basics
import { useEffect, useState } from 'react';
import { Box, keyframes } from '@mui/material';
import { Keyframes } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';

// Theming
import { themeBreakpoints as breakpoints } from '../../../shared/ui/themeVars';

// Types
import type { LightBallType, deviceWidthProp } from '../utils/types';

// Styles
import { s } from '../utils/sx';

export const LightBall = ({ color, sx = {}, opacity = 0, scale = [250, 250], radius = 0 }: LightBallType) => {

    const isXl = useMediaQuery({ minWidth: breakpoints.xl });
    const isLg = useMediaQuery({ minWidth: breakpoints.lg });
    const isMd = useMediaQuery({ minWidth: breakpoints.md });

    const [definedOpacity, setDefinedOpacity] = useState<number | string | deviceWidthProp | undefined>(getOpacityValue());
    const [appearance, setAppearance] = useState<Keyframes | undefined>();
    const styles = { ...s.lightball, ...sx };

    // Custom Opacity definer because "keyframes" doesn't support MUI's responsive values
    function getOpacityValue() {

        // Return untouched value if it's simple number or string
        if (typeof opacity === 'number' || typeof opacity === 'string') return opacity;

        // Return the responsive value relatively screen's width
        if (isXl) return opacity.xl || opacity.lg || opacity.md || opacity.xs;
        else if (isLg) return opacity.lg || opacity.md || opacity.xs;
        else if (isMd) return opacity.md || opacity.xs;
        else return opacity.xs;
    };

    // Change opacity if screen's width changed
    useEffect(() => {
        const result = getOpacityValue();
        setDefinedOpacity(result);
    }, [opacity, isXl, isLg, isMd]);

    // Define animation when opacity is defined
    useEffect(() => {
        if (definedOpacity) {
            setAppearance(keyframes`
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: ${definedOpacity};
                }
            `);
        }
    }, [definedOpacity]);

    return (
        <Box
            component='span'
            sx={{
                background: `radial-gradient(50% 50% at 50% 50%, ${color} ${radius}%, transparent)`,
                height: scale[0],
                width: scale[1],

                animationName: `${appearance}`,
                animationDelay: '0s',
                animationDuration: '2.5s',
                animationFillMode: 'forwards',
                animationTimingFunction: 'ease-in',

                ...styles,
            }}
        />
    );
};
