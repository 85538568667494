// Basics
import { SxProps } from '@mui/material';

// List of all styles
export const styles: Record<string, SxProps> = {
    agitationWrapper: { 
        position: 'relative'
    },

    codelineProviderWrapper: {
        position: 'absolute',
        width: '100%',
        zIndex: '-1',
        top: {
            xs: '-20px',
            sm: '-150px',
            xl: '-170px'
        }
    }
};

// This is a variable that structures all styles from style variable
export const structured: any = {
    wrapper: styles.agitationWrapper,
    codelineWrapper: styles.codelineProviderWrapper
};

export {
    structured as s,
    styles as raw
};