// Basics
import MediaQuery from 'react-responsive';

// Theming
import { themeBreakpoints as breakpoints } from '../../../../../../shared/ui/themeVars';

// Assets
import benefit3XS from '../../../../../../shared/assets/images/benefit_3_xs.svg';
import benefit3MD from '../../../../../../shared/assets/images/benefit_3_md.svg';
import benefit3LG from '../../../../../../shared/assets/images/benefit_3_lg.svg';
import benefit3XL from '../../../../../../shared/assets/images/benefit_3_xl.svg';

// Styles
import { s } from '../../sx';
import { AppearanceProvider, Animations } from '../../../../../../features/animations/block-appearance';

export const Frame = () => {

    return (
        <AppearanceProvider dur={1} type={Animations.opacityUp} sx={s.content.sections.benefits.cost.frame.wrapper}>
            <MediaQuery minWidth={breakpoints.xs} maxWidth={breakpoints.md - 1}>
                <img src={benefit3XS} {...s.content.sections.benefits.cost.frame.image} />
            </MediaQuery>

            <MediaQuery minWidth={breakpoints.md} maxWidth={breakpoints.lg - 1}>
                <img src={benefit3MD} {...s.content.sections.benefits.cost.frame.image} />
            </MediaQuery>

            <MediaQuery minWidth={breakpoints.lg} maxWidth={breakpoints.xl - 1}>
                <img src={benefit3LG} {...s.content.sections.benefits.cost.frame.image} />
            </MediaQuery>

            <MediaQuery minWidth={breakpoints.xl}>
                <img src={benefit3XL} {...s.content.sections.benefits.cost.frame.image} />
            </MediaQuery>
        </AppearanceProvider>
    );
};