import { SxProps } from '@mui/material';

export const s: Record<string, SxProps> = {

    lightball: {
        zIndex: -1,
        position: 'absolute',
        filter: 'blur(250px)',
        opacity: 0.75,
    }
};