// Basics
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

// Widgets
import { WarmTitle, ContrastTitle } from '../../../../widgets/titles';

// Blocks
import { TeamBlock } from './blocks/team';
import { ScaleBlock } from './blocks/scale';
import { CostBlock } from './blocks/cost';

// Theming
import { themeColors } from '../../../../shared/ui/themeVars';

// Styles
import { s } from './sx';
import { AppearanceProvider } from '../../../../features/animations/block-appearance';

export const ContentProvider = () => {

    const { t } = useTranslation();

    return (
        <Box sx={s.content.wrapper}>

            {/* Section about us */}
            <Box sx={s.content.sections.aboutUs.wrapper}>

                {/* Section: Our Idea, Our Goal */}
                <Box sx={s.content.sections.aboutUs.ourIdeaOurGoal.wrapper}>

                    {/* Title of the Section: Our Idea, Our Goal */}
                    <AppearanceProvider>
                        <Typography variant='h2' color='common.white' sx={{

                            /* Defiens gaps between title and subtitle */
                            mb: { xs: '10px', lg: '16px' }

                        }}>

                            {/* 1 Title is separated because it combines translation of each word and that each word has different color */}
                            <span>{t('about_title_our_1').toUpperCase()}</span>
                            <span style={{ color: themeColors.primary.main, textShadow: `0px 0px 20px ${themeColors.primary.light}` }}>{t('about_title_idea_2').toUpperCase()}</span>
                            <span>, {t('about_title_our_1').toUpperCase()}</span>
                            <span style={{ color: themeColors.contrast.dark, textShadow: `0px 0px 20px ${themeColors.contrast.main}` }}>{t('about_title_goal_3').toUpperCase()}</span>!

                        </Typography>
                    </AppearanceProvider>

                    {/* Subtitle of the Section: Our Idea, Our Goal */}
                    <AppearanceProvider delay={0.6}>
                        <Typography variant='h4' color='common.white'>{t('about_subtitle')}</Typography>
                    </AppearanceProvider>

                </Box>

                {/* Section: The Unique Model */}
                <Box sx={s.content.sections.aboutUs.theUniqueModel.wrapper}>

                    {/* Title of the Section: The Unique Model */}
                    <AppearanceProvider>
                        <WarmTitle sx={{

                            // Defines gaps between title and subtitle
                            mb: { xs: '10px', lg: '16px' }

                        }}>{t('model_title').toUpperCase()}</WarmTitle>
                    </AppearanceProvider>

                    {/* Subtitle of the Section The Unique Model */}
                    <AppearanceProvider delay={0.6}>
                        <Typography variant='h4' color='common.white' sx={{

                            /* Defines with of subtitle separately as it has to be thinner than title sometimes */
                            width: { md: '58%', xl: '90%' }

                        }}>{t('model_subtitle')}</Typography>
                    </AppearanceProvider>
                </Box>
            </Box>

            {/* Section of benefits */}
            <Box sx={s.content.sections.benefits.wrapper}>

                {/* Title of the benefits */}
                <AppearanceProvider dur={0.4}>
                    <ContrastTitle>{t('benefits_title').toUpperCase()}</ContrastTitle>
                </AppearanceProvider>

                {/* Benefit #1: Team of Your Dream */}
                <TeamBlock />

                {/* Benefit #2: Scalability */}
                <ScaleBlock />

                {/* Benefit #3: Cost Efficiency */}
                <CostBlock />

            </Box>

        </Box>
    );
};
