const variables = {
    $titleMargins: {
        xs: '8px 0',
        lg: '12px 0'
    },
    $subtitleMargins: {
        xs: '6px 0',
        lg: '10px 0'
    },
    $actionMargins: {
        xs: '11px 0',
        lg: '18px 0'
    },
    $blockMargins: {
        xs: '24px 0',
        lg: '40px 0'
    },
    $codeMargins: {
        xs: '10px 0',
        lg: '15px 0'
    }
};

export {
    variables as v
};