// Basics
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

// Features
import { AppearanceProvider, Animations } from '../../features/animations/block-appearance';

// Layouts
import { Layout } from '../layout';

// Error page appears mostly in the cases when requested route is not found
const Error = () => {

    const { t } = useTranslation();

    return (
        <Layout>
            <AppearanceProvider dur={1} type={Animations.opacityUp}>
                <Typography align='center' variant='h2' sx={{ mt: '250px', mb: '10px', color: '#ffffff', }}>{t('not_found_title')}</Typography>
            </AppearanceProvider>

            <AppearanceProvider delay={1} dur={0.5} type={Animations.opacityUp}>
                <Typography align='center' variant='h5' sx={{ mb: '250px', color: '#ffffff' }}>{t('not_found_subtitle')}</Typography>
            </AppearanceProvider>
        </Layout>
    );
};

export default Error;
