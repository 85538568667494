// Basics
import { SxProps, keyframes } from '@mui/material';

// Animations
export const a = {

    marquee: keyframes`
        0%   { transform: translate(0, 0); }
        100% { transform: translate(-100%, 0); }
    `

};

// Styles
export const s: Record<string, SxProps> = {
    codeline: {
        background: 'none',
        fontSize: {
            xs: '10px',
            md: '12px',
            lg: '13px',
            xl: '14px'
        },
        color: '#ffffff',
        padding: '2px !important'
    },

    codelineWrapper: {
        userSelect: 'none',
        overflow: 'hidden',
        width: '100%',
    },

    marqueeWrapper: {
        display: 'flex',
        overflow: 'hidden',
        width: '100%',
        position: 'relative'
    },

    maqrueeLine: {
        animation: `${a.marquee} 5s linear infinite`,
        minWidth: '100%',
        flex: '0 0 auto'
    }
};