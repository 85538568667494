import { RefObject, useEffect, useMemo, useState } from 'react';

const useOnScreen = (ref: RefObject<HTMLElement>) => {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(() =>
        new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    ), [ref]);

    useEffect(() => {
        if (!ref.current) return;
        observer.observe(ref.current);
        // return () => observer.disconnect();
    }, []);

    useEffect(() => { // for tests comment this useEffect
        if (isIntersecting) {
            observer.disconnect();
            return;
        }
        return () => observer.disconnect();
    }, [isIntersecting]);

    return isIntersecting;
};

export default useOnScreen;
