// Basics
import { SxProps } from '@mui/material';
import { themeColors, themeBreakpoints as breakpoints } from '../../../../shared/ui/themeVars';

// Assets
import waves from '../../../../shared/assets/animations/waves.mp4';

// List of all styles
export const styles: Record<string, SxProps> = {

    benefitWrapper: {
        position: 'relative',
        mt: { xs: '45px', xl: '145px' },
        height: {
            xs: '1590px',
            md: '2000px',
            lg: '2280px',
            xl: '3100px'
        }
    },

    backgroundProviderWrapper: {
        position: 'absolute',
        zIndex: '-2',
        width: {
            xs: breakpoints.sm + 20, md: breakpoints.md + 20, lg: breakpoints.lg + 20, xl: breakpoints.xl + 20,
        },
        height: { xs: '1710px', md: '2015px', lg: '2320px', xl: '3250px' }
    },

    lightBallOrange: {
        // opacity: { xs: 0.15, xl: 0.09 },
        right: { xs: '-280px', md: '-320px', xl: '-850px' },
        top: { xs: '220px', md: '360px', lg: '600px', xl: '800px' }
    },

    lightBallBlue: {
        // opacity: { xs: 0.15 },
        left: { xs: '-280px', md: '-320px', xl: '-850px' },
        top: { xs: '820px', md: '1160px', lg: '1400px', xl: '1800px' }
    },

    wave: {
        position: 'absolute',
        // opacity: '0.7',
        zIndex: '-10'
    },

    xsWaveUp: {
        top: '15px',
        left: '15px',
        width: '290px'
    },

    xsWaveDown: {
        bottom: '140px',
        right: '35px',
        width: '220px',
        transform: 'rotate(180deg)'
    },

    mdWaveUp: {
        top: '15px',
        left: '15px',
        width: '440px'
    },

    mdWaveDown: {
        bottom: '15px',
        right: '35px',
        width: '320px',
        transform: 'rotate(180deg)'
    },

    lgWaveUp: {
        top: '22px',
        left: '22px',
        width: '500px'
    },

    lgWaveDown: {
        bottom: '24px',
        right: '43px',
        width: '500px',
        transform: 'rotate(180deg)'
    },

    xlWaveUp: {
        top: '22px',
        left: '22px',
        width: '700px'
    },

    xlWaveDown: {
        bottom: '244px',
        right: '43px',
        width: '600px',
        transform: 'rotate(180deg)'
    },

    contentProviderWrapper: {
        /* Defines left gaps between frame and content */
        ml: { xs: '40px', lg: '70px' }
    },

    sectionAboutUsWrapper: {

        height: {
            xs: '570px',
            md: '820px',
            lg: '1090px',
            xl: '1370px'
        },

        /* Defines top gaps between frame and content */
        pt: { xs: '40px', lg: '70px' },

    },

    sectionBenefitsWrapper: {
        position: 'relative',
        width: '100%',
        height: {
            xs: '1180px',
            md: '1235px',
            lg: '1300px',
            xl: '1950px'
        }
    },

    ourIdeaOurGoal: {

        /* Defines gaps between 1st and 2nd sections */
        mb: { xs: '140px', md: '252px', lg: '470px', xl: '662px' },

        /* Defines width of the content. It's not 100% because content might touch scheme in the right */
        width: { md: '85%', lg: '60%' },

        /* Height is certainly defines so other sections didn't fly to mars as language changes and height changes automatically */
        height: { xs: '200px' }
    },

    theUniqueModel: {

        /* Defines width of the content. It's not 100% because content might touch scheme in the right */
        width: { xl: '55%', lg: '100%' }
    },

    benefitBlockWrapper: {
        /* Defines width of the block excluding a little gap so it didn't get above right-sided frame  */
        width: { xs: breakpoints.sm - 20, md: breakpoints.md - 24, lg: breakpoints.lg - 120, xl: breakpoints.xl - 120 },

        /* Defines height of the benefit block */
        height: { xs: '321px', lg: '241px', xl: '272px' },
    },

    teamBlockWrapper: {
        /* Gap between title and block */
        mt: {
            xs: '40px',
            xl: '90px'
        },

        mb: {
            xs: '0',
            md: '32px',
            lg: '48px',
            xl: '130px'
        }
    },

    scaleBlockWrapper: {
        mb: {
            xs: '110px',
            md: '110px',
            lg: '130px',
            xl: '220px'
        },

    },

    costBlockWrapper: {
        /* Gap between title and block */
        mt: '50px',
    },

    teamBlockStack: {
        position: 'relative',

        /* Defines gaps from the decorative frame */
        ml: { xs: '-8px', lg: '-36px', xl: '-24px' },
        pt: { xs: '24px', md: '30px', xl: '32px' },
    },

    scaleBlockStack: {
        position: 'relative',

        /* Defines gaps from the decorative frame */
        ml: { xs: '-8px', lg: '-36px', xl: '-24px' },
        pt: { xs: '24px', md: '30px', xl: '32px' },
    },

    costBlockStack: {
        position: 'relative',

        /* Defines gaps from the decorative frame */
        ml: { xs: '-8px', lg: '-36px', xl: '-24px' },
        pt: { xs: '24px', md: '30px', xl: '32px' },
    },

    teamFrame: {
        position: 'absolute',
        left: { xs: '-40px', lg: '-74px' },
        zIndex: '-1'
    },

    scaleFrame: {
        position: 'absolute',
        left: { xs: '-32px', lg: '0px' },
        zIndex: '-1'
    },

    costFrame: {
        position: 'absolute',
        left: { xs: '-40px', lg: '-74px' },
        zIndex: '-1'
    },

    frameImage: {
        pointerEvents: 'none'
    },

    teamDisplay: {

        /* Gaps between text and presenting image */
        mt: { xs: '16px', md: '24px', lg: '0' },
        ml: { xs: '0', lg: '6px' },

        /* Defines sizes of the box the image located in (so to center it properly) */
        width: { xs: '64%', md: '50%', lg: '80%' },
        height: { xs: 'auto', lg: '175px', xl: '200px' },

        /* Center the image inside of Box comp */
        display: 'flex', alignItems: 'center', justifyContent: 'center',
    },

    scaleDisplay: {

        /* Gaps between text and presenting image */
        mt: { xs: '16px', md: '32px', lg: '0' },
        ml: { xs: '0', lg: '10px' },

        /* Defines sizes of the box the image located in */
        width: { xs: '75%', md: '70%', lg: '80%' },

    },

    costDisplay: {
        mt: '16px',
        width: '100%',

        // Define gaps between steps
        gap: {
            lg: '16px 0',
            xl: '24px 0'
        }
    }
};

// This is a variable that structures all styles from style variable
export const structured: any = {

    wrapper: styles.benefitWrapper,

    light: {

        orange: {
            opacity: { xs: 0.15, lg: 0.09 },
            sx: styles.lightBallOrange,
            color: themeColors.contrast.dark,
            radius: 100,
            scale: [
                { xs: '700px', md: '850px', lg: '900px', xl: '1000px' },
                { xs: '420px', md: '500px', lg: '615px', xl: '1200px' }
            ]
        },

        blue: {
            opacity: 0.15,
            sx: styles.lightBallBlue,
            color: themeColors.primary.main,
            radius: 100,
            scale: [
                { xs: '700px', md: '850px', lg: '900px', xl: '1000px' },
                { xs: '420px', md: '500px', lg: '615px', xl: '1200px' }
            ]
        }

    },

    background: {

        wrapper: styles.backgroundProviderWrapper,

        static: {
            img: {
                pointerEvents: 'none',
                position: 'absolute',
            },
            videoPlayer: {
                src: waves,
                volume: 0,
                doLoop: true,
                doStart: true,
                doControl: false
            }
        },
        dynamic: {
            xs: {
                waveUp: { ...styles.wave, ...styles.xsWaveUp },
                waveDown: { ...styles.wave, ...styles.xsWaveDown }
            },
            md: {
                waveUp: { ...styles.wave, ...styles.mdWaveUp },
                waveDown: { ...styles.wave, ...styles.mdWaveDown }
            },
            lg: {
                waveUp: { ...styles.wave, ...styles.lgWaveUp },
                waveDown: { ...styles.wave, ...styles.lgWaveDown }
            },
            xl: {
                waveUp: { ...styles.wave, ...styles.xlWaveUp },
                waveDown: { ...styles.wave, ...styles.xlWaveDown }
            }
        }

    },

    content: {

        wrapper: styles.contentProviderWrapper,

        sections: {
            aboutUs: {
                wrapper: styles.sectionAboutUsWrapper,

                ourIdeaOurGoal: {
                    wrapper: styles.ourIdeaOurGoal
                },

                theUniqueModel: {
                    wrapper: styles.theUniqueModel
                }

            },

            benefits: {
                wrapper: { ...styles.sectionBenefitsWrapper, ...styles.benefitBlockWrapper },

                team: {
                    wrapper: styles.teamBlockWrapper,
                    stack: {
                        sx: styles.teamBlockStack,
                        direction: { xs: 'column', lg: 'row' }
                    },

                    frame: {

                        wrapper: styles.teamFrame,
                        image: {
                            style: styles.frameImage,
                            alt: 'Benefit\'s Decorative Frame'
                        }

                    },

                    display: {

                        wrapper: styles.teamDisplay

                    }
                },

                scale: {
                    wrapper: { ...styles.scaleBlockWrapper, ...styles.benefitBlockWrapper },
                    stack: {
                        sx: styles.scaleBlockStack,
                        direction: { xs: 'column-reverse', lg: 'row' }
                    },

                    frame: {

                        wrapper: styles.scaleFrame,
                        image: {
                            style: styles.frameImage,
                            alt: 'Benefit\'s Decorative Frame'
                        }

                    },

                    display: {

                        wrapper: styles.scaleDisplay

                    }
                },

                cost: {
                    wrapper: { ...styles.costBlockWrapper, ...styles.benefitBlockWrapper },
                    stack: {
                        sx: styles.costBlockStack,
                        direction: { xs: 'column', lg: 'row' }
                    },

                    frame: {

                        wrapper: styles.costFrame,
                        image: {
                            style: styles.frameImage,
                            alt: 'Benefit\'s Decorative Frame'
                        }

                    },

                    display: {

                        wrapper: styles.costDisplay

                    }
                }
            }
        },

    }

};

export {
    structured as s,
    styles as raw
};