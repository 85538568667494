// Basics
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

// Widgets
import { ColdTitle } from '../../../../../../widgets/titles';
import { Bubble } from '../../../../../../widgets/floating-bubble';

// Benefits' Frames
import { Frame } from './Frame';

// Benefits' Displays
import { Display } from './Display';

// Styles
import { s } from '../../sx';
import { v } from '../../../../../../shared/ui/sxVars';
import { AppearanceProvider } from '../../../../../../features/animations/block-appearance';

export const CostBlock = () => {

    const { t } = useTranslation();

    return (
        <Box sx={s.content.sections.benefits.cost.wrapper}>

            {/* This is a frame for the Benefit's block */}
            <Frame />

            {/* Stack Container, wraps presenting image and text for small screens */}
            <Stack {...s.content.sections.benefits.cost.stack}>
                <Box sx={{

                    /* Defines width relatively (as "Stack" comp is parental) */
                    width: { xs: '100%', lg: '147%' }
                }}>

                    {/* Title of the benefit block */}
                    <AppearanceProvider sx={{ m: v.$titleMargins, width: '90%' }} delay={1.4} dur={0.4}>
                        <ColdTitle variant='h3'>{t('cost_title').toUpperCase()}</ColdTitle>
                    </AppearanceProvider>

                    {/* Subtitle of the benefit block */}
                    <AppearanceProvider sx={{ m: v.$subtitleMargins, width: { xs: '80%', lg: '90%' } }} delay={1.8} dur={0.4}>
                        <Typography color="common.white" variant='h5'>{t('cost_subtitle')}</Typography>
                    </AppearanceProvider>
                </Box>
                <Display />

                {/* Decorative Bubbles */}
                <>
                    <Bubble dur={8} sx={{
                        zIndex: '-1',
                        top: { xs: '160px', md: '230px', lg: '330px', xl: '430px', },
                        right: { xs: '240px', md: '340px', lg: '450px', xl: '500px' }
                    }} sizes={[
                        { xs: 30, md: 50, lg: 55, xl: 75 }, { xs: 30, md: 50, lg: 55, xl: 75 }
                    ]} />

                    <Bubble dur={10} sx={{
                        zIndex: '-1',
                        top: { xs: '140px', md: '180px', lg: '240px', xl: '290px', },
                        right: { xs: '200px', md: '240px', lg: '340px', xl: '620px' }
                    }} sizes={[
                        { xs: 15, md: 30, lg: 70, xl: 90 }, { xs: 15, md: 30, lg: 70, xl: 90 }
                    ]} />

                    <Bubble dur={6.5} sx={{
                        display: { xs: 'none', lg: 'block' },
                        zIndex: '-1',
                        top: { lg: '190px', xl: '230px', },
                        right: { lg: '500px', xl: '450px' }
                    }} sizes={[
                        { xs: 50, md: 50, lg: 50, xl: 50 }, { xs: 50, md: 50, lg: 40, xl: 50 }
                    ]} />
                </>

            </Stack>
        </Box>
    );
};