import { SxProps } from '@mui/material';
import React from 'react';

export enum Animations {
    appearance = 'appearance',
    scaleUp = 'scaleUp',
    opacityUp = 'opacityUp',
    opacityDown = 'opacityDown',
    leftToRight = 'leftToRight',
    rightToLeft = 'rightToLeft'
}

export interface AppearanceProviderParams {
    children?: React.ReactElement | React.ReactElement[] | string

    // Functions defining the duration and delay for the animation
    delay?: number,
    dur?: number,

    // Params related to "Box" MUI's component
    component?:
        'div'
        | 'section'
        | 'article'
        | 'span'
        | 'main'
        | 'aside'
        | 'nav'
        | 'header'
        | 'footer'

    sx?: SxProps,

    // This type defines what type of appearance it's going to be
    type?: Animations
}
