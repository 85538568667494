// Basics
import { useLayoutEffect, useState } from 'react';
import MediaQuery from 'react-responsive';
import { Stack, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

// Widgets
import { ColdTitle } from '../../../../../widgets/titles';
import { Review, getRandomReviews } from '../../../../../widgets/review';

// Entities
import { LightBall } from '../../../../../entities/light-ball';

// Theming
import { themeBreakpoints } from '../../../../../shared/ui/themeVars';

// Features
import { AppearanceProvider } from '../../../../../features/animations/block-appearance';

// Styles
import { v } from '../../../../../shared/ui/sxVars';

interface ReviewContentType {
    status: 'customer' | 'developer',
    username: string,
    content: string
}

export default function ReviewBlock() {

    // States
    const [reviews, setReviews] = useState<ReviewContentType[]>([]);

    // Get Translations
    const { t } = useTranslation();

    // Gaps between reviews in Material-UI format
    const verticalGaps = { xs: '10px 0', lg: '16px 0' };
    const horizontalGaps = { xs: '0 10px', lg: '0 16px' };

    useLayoutEffect(() => {
        (async () => {
            const response = await getRandomReviews(9);
            setReviews(response);
        })();
    }, []);

    return (
        <Stack direction='column' sx={{
            minHeight: { xs: '500px', md: '600px', lg: '700px', xl: '800px' },
            m: v.$blockMargins
        }}>

            <LightBall opacity={0.1} color='#3D9AD1' radius={100} sx={{
                position: 'absolute',
                filter: 'blur(1600px)',
                left: 0
            }}
                scale={[{ xs: 600, sm: 1250 }, '100%']}
            />

            {/* Title of the block with text gradient */}
            <AppearanceProvider sx={{ m: v.$titleMargins }} dur={0.4}>
                <ColdTitle>{t('reviews_title')}</ColdTitle>
            </AppearanceProvider>

            {/* Content of the Block */}
            <Stack direction='row' sx={{
                gap: horizontalGaps
            }}>

                {/* For Small Screens (Small Phone, Average Phone, Tablet) */}
                <MediaQuery minWidth={themeBreakpoints.xs} maxWidth={themeBreakpoints.xl - 1}>

                    {/* Boxes are columns, reviews inside are rows, technically are reviews themselves */}
                    <Box sx={{ width: { xs: 'calc(50% - 10px)', lg: 'calc(50% - 19px)' } }}>
                        <AppearanceProvider dur={0.4}><Review sxFrame={{ m: verticalGaps }} isRandom={false} data={reviews[0]} /></AppearanceProvider>
                        <AppearanceProvider dur={0.4}><Review sxFrame={{ m: verticalGaps }} isRandom={false} data={reviews[1]} /></AppearanceProvider>
                        <AppearanceProvider dur={0.4}><Review sxFrame={{ m: verticalGaps }} isRandom={false} data={reviews[2]} /></AppearanceProvider>
                    </Box>
                    <Box sx={{ width: { xs: 'calc(50% - 10px)', lg: 'calc(50% - 19px)' } }}>
                        <AppearanceProvider dur={0.4}><Review sxFrame={{ m: verticalGaps }} isRandom={false} data={reviews[3]} /></AppearanceProvider>
                        <AppearanceProvider dur={0.4}><Review sxFrame={{ m: verticalGaps }} isRandom={false} data={reviews[4]} /></AppearanceProvider>
                        <AppearanceProvider dur={0.4}><Review sxFrame={{ m: verticalGaps }} isRandom={false} data={reviews[5]} /></AppearanceProvider>
                    </Box>

                </MediaQuery>

                {/* For Big Screens (Desktop) */}
                <MediaQuery minWidth={themeBreakpoints.xl}>

                    {/* Boxes are columns, reviews inside are rows, technically are reviews themselves */}
                    <Box sx={{ width: { xs: 'calc(50% - 10px)', lg: 'calc(50% - 19px)' } }}>
                        <AppearanceProvider dur={0.4}><Review sxFrame={{ m: verticalGaps }} isRandom={false} data={reviews[0]} /></AppearanceProvider>
                        <AppearanceProvider dur={0.4}><Review sxFrame={{ m: verticalGaps }} isRandom={false} data={reviews[1]} /></AppearanceProvider>
                        <AppearanceProvider dur={0.4}><Review sxFrame={{ m: verticalGaps }} isRandom={false} data={reviews[2]} /></AppearanceProvider>
                    </Box>
                    <Box sx={{ width: { xs: 'calc(50% - 10px)', lg: 'calc(50% - 19px)' } }}>
                        <AppearanceProvider dur={0.4}><Review sxFrame={{ m: verticalGaps }} isRandom={false} data={reviews[3]} /></AppearanceProvider>
                        <AppearanceProvider dur={0.4}><Review sxFrame={{ m: verticalGaps }} isRandom={false} data={reviews[4]} /></AppearanceProvider>
                        <AppearanceProvider dur={0.4}><Review sxFrame={{ m: verticalGaps }} isRandom={false} data={reviews[5]} /></AppearanceProvider>
                    </Box>
                    <Box sx={{ width: { xs: 'calc(50% - 10px)', lg: 'calc(50% - 19px)' } }}>
                        <AppearanceProvider dur={0.4}><Review sxFrame={{ m: verticalGaps }} isRandom={false} data={reviews[6]} /></AppearanceProvider>
                        <AppearanceProvider dur={0.4}><Review sxFrame={{ m: verticalGaps }} isRandom={false} data={reviews[7]} /></AppearanceProvider>
                        <AppearanceProvider dur={0.4}><Review sxFrame={{ m: verticalGaps }} isRandom={false} data={reviews[8]} /></AppearanceProvider>
                    </Box>
                </MediaQuery>
            </Stack>

        </Stack>
    );
}
