import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { actions, ModalType } from '../../redux/modalSlice';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { submitModal } from '../../redux/modalThunk';
import { LoadingButton } from '@mui/lab';

const { closeModal, setEmail, setName, setContent } = actions;

const HireOrderModal = () => { // todo footer: confidential policy & user agreement(check design)
    const dispatch = useAppDispatch();
    const { active, type, loading } = useAppSelector(s=>s.modalSlice);
    const isOrder = type === ModalType.order;

    const onCloseClick = () => dispatch(closeModal());

    const onEmailChange = (x: ChangeEvent<HTMLTextAreaElement>) => dispatch(setEmail(x.target.value));
    const onNameChange = (x: ChangeEvent<HTMLTextAreaElement>) => dispatch(setName(x.target.value));
    const onInformationChange = (x: ChangeEvent<HTMLTextAreaElement>) => dispatch(setContent(x.target.value));

    const { t } = useTranslation('translation', { keyPrefix: 'modal' });

    const onModalSubmit = () => dispatch(submitModal());

    return (
        <Modal open={active} onClose={onCloseClick}>
            <Box sx={{
                position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                maxWidth: 700, minWidth: 300, width: '100%', bgcolor: 'rgba(0, 0, 0, 0.4)', backdropFilter: 'blur(9px)',
                borderRadius: 2, display: 'flex', flexDirection: 'column', gap: 2, padding: 3
            }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant={'h4'} color={'white'}>{t(isOrder ? 'headerOrder' : 'headerHire')}</Typography>
                    <Button onClick={onCloseClick}><CloseIcon/></Button>
                </Box>
                <TextField onChange={onEmailChange} variant={'standard'} label={t('email')}/>
                <TextField onChange={onNameChange} variant={'standard'} label={t('name')}/>
                <TextField onChange={onInformationChange} variant={'outlined'} label={t(isOrder ? 'informationOrder' : 'informationHire')} multiline rows={10}/>
                <LoadingButton onClick={onModalSubmit} loading={loading} variant={'contained'} sx={{ width: 'max-content', p: '10px 15px 10px 15px' }}>Submit</LoadingButton>
            </Box>
        </Modal>
    );
};

export default HireOrderModal;
