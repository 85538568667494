// Basics
import { Typography } from '@mui/material';

// Types
import { TitleParams } from '../utils/types';

// Styles
import { s } from '../utils/sx';

// Creates title with cold gradient from light blue to white
export const Title = ({ children, sx, variant }: TitleParams) => {

    // Combination of all styles for certain Title
    const titleStyles = {
        ...s.warmTitle,
        ...s.title,
        ...sx
    };

    return (
        <Typography variant={variant ? variant : 'h2'} color='common.white' sx={titleStyles}>{children}</Typography>
    );
};