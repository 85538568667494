// Basics
import { Box, SxProps } from '@mui/material';
import { ReactNode } from 'react';

// Theming
import { themeContentWidth } from '../../../shared/ui/themeVars';

export const ContentBox = ({ children, sx }: { children?: ReactNode, sx?: SxProps }) => {
    return (
        <Box component="section" sx={{
            m: '0 auto',
            width: {
                xs: themeContentWidth.xs,
                md: themeContentWidth.md,
                lg: themeContentWidth.lg,
                xl: themeContentWidth.xl
            },
            ...sx
        }}>
            {children}
        </Box>
    );
};