// Basics
import { AppBar } from '@mui/material';

// Features
import { AppearanceProvider } from '../../../features/animations/block-appearance';

// Inner UI
import { Logo } from './Logo';
import { Navbar } from './Navbar';

// Styles
import { s } from '../utils/sx';

export const Header = () => {
    return (

        // Header Component
        <AppBar sx={s.headerWrapper}>

            {/* Content wrapper */}
            <AppearanceProvider sx={s.headerContentWrapper} dur={1}>

                {/* Left part with logo */}
                <Logo />

                {/* Navigation bar */}
                <Navbar />
            </AppearanceProvider>
        </AppBar>
    );
};
