import { createSlice } from '@reduxjs/toolkit';
import { submitModal } from './modalThunk';
import notify from '../shared/utils/notify';
import i18next from 'i18next';

export enum ModalType {
    'order' = 'order',
    'job' = 'job',
}

export type ModalData = {
    type: ModalType;
    active: boolean;
    email: string;
    name: string;
    content: string;
    loading: boolean;
    reject: boolean;
    lang: boolean; // true if ru, false if en
}

const lang = localStorage.getItem('lang');

const initialState: ModalData = {
    type: ModalType.order,
    active: false,
    email: '',
    name: '',
    content: '',
    loading: false,
    reject: false,
    lang: lang === 'ru'
};

const getParamSetter = <T>(field: keyof ModalData) => (state: ModalData, { payload }: {payload: T}) => {
    (state[field] as T) = payload;
};

const modalSlice = createSlice({
    name: 'uiSlice',
    initialState,
    reducers: {
        openModal: (state, { payload }: {payload: ModalType}) => {
            state.type = payload;
            state.active = true;
        },
        closeModal: (state) => {
            state.active = false;
        },
        setModalActive: getParamSetter<boolean>('active'),
        setEmail: getParamSetter<string>('email'),
        setName: getParamSetter<string>('name'),
        setContent: getParamSetter<string>('content'),
        setLanguage: state => {
            const lang = state.lang;
            state.lang = !lang;
            localStorage.setItem('lang', lang ? 'en' : 'ru');
        }
    },
    extraReducers: b => {
        b
            .addCase(submitModal.pending, state => {
                state.loading = true;
                state.reject = !validate(state);
            })
            .addCase(submitModal.rejected, state => {
                state.loading = false;
                if (state.reject) return;
                notify(i18next.t('modal.error'), 'error');
            })
            .addCase(submitModal.fulfilled, state => {
                state.loading = false;
                notify(i18next.t('modal.success'));
            });
    }
});

export default modalSlice.reducer;
export const actions = modalSlice.actions;

type Validator = (s: ModalData) => boolean

const isEmailValid: Validator = ({ email }) => /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i.test(email);
const isNameValid: Validator = ({ name }) => /^(?=[a-zA-Zа-яА-Я]*$).{5,}/.test(name);
const isContentValid: Validator = ({ content }) => /^(?!\s*$).{20,}/.test(content);

const validators: {v: Validator, et: string}[] = [
    { v: isEmailValid, et: 'modal.invalidEmail' },
    { v: isNameValid, et: 'modal.invalidName' },
    { v: isContentValid, et: 'modal.invalidContent' }
];

const validate: (state: ModalData) => boolean = (s) => {
    for (const validator of validators) {
        if (!validator.v(s)){
            notify(i18next.t(validator.et), 'error');
            return false;
        }
    }
    return true;
}; // true if valid, false if invalid
