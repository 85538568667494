// Define types to names of all existing colors
type Colors = 'primary' | 'secondary' | 'contrast' | 'error' | 'success' | 'info'

// Define types of the color
type Color = {
    dark: string;
    main: string;
    light: string;
}

// Define all main colors
export const primary: Color = {
    dark: '#0969A2',
    main: '#3D9AD1',
    light: '#64A8D1'
};

export const secondary: Color = {
    dark: '#6B94AD',
    main: '#8BB9D4',
    light: '#A2C2D4'
};

export const contrast: Color = {
    dark: '#FF8C00',
    main: '#FFA940',
    light: '#FFC073'
};

export const error: Color = {
    dark: '#C62828',
    main: '#D32F2F',
    light: '#EF5350'
};

export const success: Color = {
    dark: '#1B5E20',
    main: '#2E7D32',
    light: '#4CAF50'
};

export const info: Color = {
    dark: '#01579B',
    main: '#0288D1',
    light: '#03A9F4'
};

// List of theme colors
export const themeColors: Record<Colors, Color> = {
    primary: primary,
    secondary: secondary,
    contrast: contrast,
    error: error,
    success: success,
    info: info
};

// These are breakpoints for MUI theme
export const themeBreakpoints = {
    xs: 0,
    sm: 320,
    md: 480,
    lg: 789,
    xl: 1080
};

// These are variables that used to define what width of the content
// will indeed be used with the variable from "themeBreakpoints"
export const themeContentWidth = {
    xs: 320,
    md: 480,
    lg: 789,
    xl: 1080
};

// List of font types and their sizes on different screens
export const themeFontSize = {
    h6: {
        xs: '9px',
        md: '13px',
        lg: '14px',
        xl: '15px',
    },
    h5: {
        xs: '12px',
        md: '14px',
        lg: '15px',
        xl: '19px',
    },
    h4: {
        xs: '12px',
        md: '16px',
        lg: '19px',
        xl: '23px',
    },
    h3: {
        xs: '16px',
        md: '20px',
        lg: '26px',
        xl: '28px',
    },
    h2: {
        xs: '19px',
        md: '24px',
        lg: '32px',
        xl: '38px',
    },
    h1: {
        xs: '24px',
        md: '40px',
        lg: '46px',
        xl: '54px',
    },
    button: {
        xs: '10px',
        md: '13px',
        lg: '14px',
        xl: '16px'
    }
};