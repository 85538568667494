// Basics
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

// Features
import { CodeLineProvider } from '../../../../features/moving-code';
import { AppearanceProvider } from '../../../../features/animations/block-appearance';

// Widgets
import { ContentBox } from '../../../../widgets/content-box';
import { OrderActionBar } from '../../../../widgets/order-action-bar';

// Entities
import { LightBall } from '../../../../entities/light-ball';

// Styles
import { s } from './sx';
import { v } from '../../../../shared/ui/sxVars';

export const Introduce = () => {

    const { t } = useTranslation();

    return (
        <Box component='section' sx={s.wrapper}>

            {/* Light flashes for the page, gives more atmosphere */}
            <Box sx={s.light.wrapper}>
                {
                    // Maps 3 light balls for introductions, all styles and positions are defined in "sx.ts" file
                    ['orange', 'blue', 'dark'].map((name, key) => <LightBall key={key} {...s.light[name]} />)
                }
            </Box>

            <CodeLineProvider />

            {/* Main content of the Block */}
            <ContentBox sx={s.contentWrapper}>

                {/* Box centering content */}
                <Box sx={s.centeringWrapper}>

                    {/* Small Code Line (In the Title) */}
                    <AppearanceProvider sx={s.uptitle.wrapper} delay={0}>

                        {/* Left title "const codeWave =" */}
                        <Box sx={s.uptitle.left}>
                            {/* Each component is a part with different colors, made with Typoghraphy to use variant="h5" */}
                            <Typography variant='h5' component='span' sx={{ color: '#063E60' }}>{'const '}</Typography>
                            <Typography variant='h5' component='span' color='contrast.main'>{'code'}</Typography>
                            <Typography variant='h5' component='span' color='primary.light'>{'Wave'}</Typography>
                            <Typography variant='h5' component='span' sx={{ color: '#737373' }}>{' ='}</Typography>
                        </Box>

                        {/* Little left "return" sign */}
                        <Box sx={s.uptitle.right}>

                            <Typography variant='h5' sx={{
                                color: '#72366F',
                                mb: { xs: '-5px', lg: '-6px' }
                            }}>return</Typography>

                            <Typography variant='h5' sx={{
                                color: '#737373',
                                mb: { xs: '-5px', md: '-15px', lg: '-20px' }
                            }}>v</Typography>

                        </Box>

                    </AppearanceProvider>

                    {/* Title Code Line */}
                    <Typography sx={{ m: v.$titleMargins }} component='div' align='center' color='common.white'>
                        <AppearanceProvider delay={0.6}>
                            <Typography variant='h1' component='span' sx={{ color: '#72366F' }}>{'('}</Typography>
                            <Typography variant='h1' component='span' color='primary.main'>{t('idea').toUpperCase()}</Typography>
                            <Typography variant='h1' component='span' sx={{ color: '#72366F' }}>{')'}</Typography>
                            <Typography variant='h1' component='span' sx={{ color: '#063E60' }}>{' => {'}</Typography>
                            <Typography variant='h1' component='span' color='contrast.dark'>{t('project').toUpperCase()}</Typography>
                            <Typography variant='h1' component='span' sx={{ color: '#063E60' }}>{'}'}</Typography>
                        </AppearanceProvider>
                    </Typography>

                    {/* Subtitle */}
                    <Typography sx={{ m: v.$subtitleMargins }} component='article' align='center' color='common.white' variant='h4'>
                        <AppearanceProvider delay={0.9}>
                            {t('main_subtitle')}
                        </AppearanceProvider>
                    </Typography>

                    {/* Action Bar (Buttons) */}
                    <AppearanceProvider delay={1.2}>
                        <OrderActionBar />
                    </AppearanceProvider>
                </Box>

            </ContentBox>
        </Box>
    );
};
