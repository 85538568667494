// Basics
import { SxProps } from '@mui/material';
import adapter from 'webrtc-adapter';
import { themeColors } from '../../../../shared/ui/themeVars';

// List of all styles
const styles: Record<string, SxProps> = {

    introductionWrapper: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap'
    },

    contentWrapper: {
        marginTop: '100px'
    },

    centeringWrapper: {
        display: 'flex', alignItems: 'center', justifyContent: 'center', flexFlow: 'column nowrap',
        height: { xs: '320px', md: '450px', lg: '600px', xl: '600px' },
        mb: { xs: '100px', md: '82px', lg: '130px', xl: '200px' }
    },

    lightBallWrapper: {
        zIndex: '-1',
        overflow: 'hidden'
    },

    lightBallOrange: {
        transform: 'scale(200%)',
        right: '-150px',
    },

    lightBallBlue: {
        transform: 'scale(200%) translateY(200px)',
        left: '-150px'
    },

    lightBallDark: {
        transform: 'scale(200%) translateY(200px)',
        left: {
            xs: 'calc(50% - 125px)',
            md: 'calc(50% - 180px)',
            xl: 'calc(50% - 300px)'
        },
        top: '-150px'
    },

    uptitle: {
        width: { xs: '75%', md: '80%', lg: '59%', xl: '50%' },
        display: 'flex',
        alignItems: 'flex-end'
    }

};

// This is a variable that structures all styles from style variable
const structured: any = {

    wrapper: styles.introductionWrapper,
    contentWrapper: styles.contentWrapper,
    centeringWrapper: styles.centeringWrapper,

    light: {
        wrapper: styles.lightBallWrapper,

        orange: {
            sx: styles.lightBallOrange,
            scale: [
                { xs: '250px', md: '200px', xl: '300px' },
                { xs: '250px', md: '360px', xl: '500px' }
            ],
            color: themeColors.contrast.dark + 'EE',
            radius: adapter.browserDetails.browser === 'firefox' ? 50 : 60,

            // Define responsive brightness
            opacity: {
                // As firefox and chrome-like browser render light differently, opacity defiend different for each browser
                xs: adapter.browserDetails.browser === 'firefox' ? 0.4 : 0.7,
                md: adapter.browserDetails.browser === 'firefox' ? 0.4 : 0.8,
                xl: adapter.browserDetails.browser === 'firefox' ? 0.4 : 1
            }
        },

        blue: {
            sx: styles.lightBallBlue,
            scale: [
                { xs: '250px', md: '200px', xl: '300px' },
                { xs: '250px', md: '360px', xl: '500px' }
            ],
            color: themeColors.primary.dark + 'EE',
            radius: adapter.browserDetails.browser === 'firefox' ? 50 : 60,

            // Define responsive brightness
            opacity: {
                // As firefox and chrome-like browser render light differently, opacity defiend different for each browser
                xs: adapter.browserDetails.browser === 'firefox' ? 0.4 : 0.7,
                md: adapter.browserDetails.browser === 'firefox' ? 0.4 : 0.8,
                xl: adapter.browserDetails.browser === 'firefox' ? 0.4 : 1
            }
        },

        dark: {
            sx: styles.lightBallDark,
            scale: [
                { xs: '250px', md: '200px', xl: '400px' },
                { xs: '250px', md: '360px', xl: '600px' }
            ],
            color: '#000000',
            radius: adapter.browserDetails.browser === 'firefox' ? 50 : 100,
            // Define responsive brightness
            // As firefox and chrome-like browser render light differently, opacity defiend different for each browser
            opacity: adapter.browserDetails.browser === 'firefox' ? 0.8 : 1,
        },

    },

    uptitle: {
        wrapper: styles.uptitle,
        right: {
            display: 'flex',
            flexFlow: 'column nowrap'
        },
        left: {
            mr: {
                xs: '20px',
                md: '70px',
                lg: '110px',
                xl: '120px'
            }
        }
    }

};

export {
    structured as s,
    styles as raw
};