// Basics
import { createBrowserRouter } from 'react-router-dom';

// Pages
import Landing from '../pages/landing';
import Error from '../pages/error';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Landing/>,
    },
    {
        path: '/*',
        element: <Error/>
    }
]);

export default router;
