// Layouts
import { Layout } from '../layout';

// Blocks of the Page
import { Introduce, Benefits, Agitation } from './blocks';

// Landing page is the main page of the Project
const Landing = () => {

    return (
        <Layout>

            {/* List of blocks of the page */}
            <Introduce/>
            <Benefits/>
            <Agitation/>

        </Layout>
    );
};

export default Landing;
